import { forTarget } from '@core/content'
import { mount } from '@core/mount'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  const INTERSTITIAL_TIMEOUT = 4000

  /**
   * pauseThenRedirect
   *
   * grabs an href from the query string, waits a few seconds,
   * then navigates to the href
   */
  function pauseThenRedirect () {
    const params = new URLSearchParams(window.location.search)
    const href = params.get('href')
    const message = params.get('message')
    const messageElement = forTarget<HTMLElement>(root, 'message')

    if (message && messageElement) {
      messageElement.innerText = message
    }

    setTimeout(() => {
      if (href) {
        window.location = (href) as unknown as Location
      } else {
        window.location = '/' as unknown as Location
      }
    }, INTERSTITIAL_TIMEOUT)
  }

  return {
    start: mount(root, pauseThenRedirect),
  }
}
