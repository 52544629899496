import { assertNonNullish } from '@lib/assert'
import { BehaviorSubject } from 'rxjs'
import { clearContents, fromTemplate } from '@core/template'
import { forTarget } from '@core/content'
import { mount } from '@core/mount'
import BlogFiltersQueryParams from '@modules/BlogFiltersQueryParams'
import BlogFiltersShop from '@modules/BlogFiltersShop'
import moment from 'moment'
import type { ComponentActions } from '@core/bootstrap'

type FilterComponentProps = {
  filteredPosts$: BehaviorSubject<Record<string, any>[]>
}

export default function (root: RootElement, { filteredPosts$ }: FilterComponentProps): ComponentActions {

  /**
   * showNoResultMessage
   *
   * @param container
   */
  function showNoResultsMessage (container: HTMLDivElement) {
    container.innerHTML = '<div>No posts match this criteria.</div>'
  }

  /**
   * updateLinks
   *
   * @param item
   * @param templateEl
   */
  function updateLinks (item: any, templateEl: HTMLElement) {
    forTarget<HTMLLinkElement>(templateEl, 'link-url')!.href = `/blog/${item.slug}`
  }

  /**
   * updateImages
   *
   * @param item
   * @param templateEl
   */
  function updateImages (item: any, templateEl: HTMLElement) {
    const image = forTarget<HTMLImageElement>(templateEl, 'image')!
    image.alt = item.name
    image.src = item['main-image'].url
  }

  /**
   * updateCategory
   *
   * @param item
   * @param templateEl
   */
  function updateCategory (item: any, templateEl: HTMLElement) {
    const blogCategory = forTarget<HTMLElement>(templateEl, 'blog-category')!
    blogCategory.innerHTML = item.category.name
  }

  /**
   * updateDate
   *
   * @param item
   * @param templateEl
   */
  function updateDate (item: any, templateEl: HTMLElement) {
    const postDateEl = forTarget<HTMLElement>(templateEl, 'post-date')!
    const postDate = moment(item['post-date'], 'YYYY-MM-DDThh:mm:ss.sTZD').utc().format('LL')
    postDateEl.innerHTML = postDate
  }

  /**
   * populateContainer
   *
   * @param items
   */
  function populateContainer (items: Maybe<Record<string, any>[]>) {
    if (!items) return
    const container = root.querySelector<HTMLDivElement>('[dk-filter-target]')!
    assertNonNullish(container, 'Missing element with [dk-filter-target]')
    clearContents(container)

    if (!items.length) return showNoResultsMessage(container)

    const itemsArray = []

    for (const item of items) {
      const el = fromTemplate('blog-post', item)!
      updateLinks(item, el)
      updateImages(item, el)
      updateCategory(item, el)
      updateDate(item, el)
      assertNonNullish(el, 'No items template')

      itemsArray.push({ el, item })
    }

    itemsArray.sort(function (a, b) {
      const aDate = moment(a.item['post-date'], 'YYYY-MM-DDThh:mm:ss.sTZD').utc().unix()
      const bDate = moment(b.item['post-date'], 'YYYY-MM-DDThh:mm:ss.sTZD').utc().unix()
      return bDate - aDate
    })

    itemsArray.forEach((item) => {
      container.append(item.el)
    })
  }

  return {
    start: mount(
      root,
      () => BlogFiltersShop(filteredPosts$),
      BlogFiltersQueryParams,
    ),
    watch: [
      {
        target: filteredPosts$,
        actions: [populateContainer],
      },
    ],
  }
}
