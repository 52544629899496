import { BehaviorSubject } from 'rxjs'
import { ComponentActions } from '@core/bootstrap'
import { ProductByPKQuery } from '@lib/comparisonTrigger'
import { gqlQuery } from '@core/gqlQuery'
import { mount } from '@core/mount'
import { on } from '@core/on'
import { retry } from 'rxjs/operators'

type ComparisonSetProps = {
  products$: BehaviorSubject<any[]>
}

export default function ComparisonSet (root: HTMLElement, { products$ }: ComparisonSetProps): ComponentActions {
  /**
   * addAllProductsToComparison
   *
   * removes all products in the products$ Subject and replaces them with each from the common comparisons section
   *
   * @param event
   */
  async function addAllProductsToComparison (event: Event) {
    event.preventDefault()

    const slugs = Array.from(root.querySelectorAll('[data-product-slug]'))
      .map((node: any) => node.dataset.productSlug)

    const products = await Promise.all(slugs.map(async (pk: any) => {
      const { response } = await gqlQuery({ query: ProductByPKQuery, variables: { pk } }).pipe(retry(3)).toPromise()
      return {
        [response.data.product.slug]: response.data.product,
      }
    }))

    const productsObject = Object.assign({}, ...products)

    products$.next(productsObject)
    window.location.pathname = '/comparison'
  }

  return {
    start: mount(root, on('compare-products', addAllProductsToComparison)),
  }
}
