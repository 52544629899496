import { forTarget, hideElement, showElement } from '@core/content'
import { BehaviorSubject } from 'rxjs'
import * as StateMachine from './stateMachine'

export const hideAllCards = (root: RootElement) => {
  const cards = Array.from(root.querySelectorAll<HTMLElement>('[dk-target^="card-"]'))
  cards.forEach((card) => hideElement(card))
}

export const handleStep = (root: RootElement, step: StateMachine.Steps) => {
  switch (step) {
    case StateMachine.Steps.Step0:
      showElement(root.querySelector('[dk-target="card-0"]')!)
      break;
    case StateMachine.Steps.Step1:
      showElement(root.querySelector('[dk-target="card-1"]')!)
      break;
    case StateMachine.Steps.Step2:
      showElement(root.querySelector('[dk-target="card-2"]')!)
      break;
    case StateMachine.Steps.Step3:
      showElement(root.querySelector('[dk-target="card-3"]')!)
      break;
    case StateMachine.Steps.Step4:
      showElement(root.querySelector('[dk-target="card-4"]')!)
      break;
    case StateMachine.Steps.Step5:
      showElement(root.querySelector('[dk-target="card-5"]')!)
      break;
    case StateMachine.Steps.Step6:
      showElement(root.querySelector('[dk-target="card-6"]')!)
      break;
    case StateMachine.Steps.Step7:
      showElement(root.querySelector('[dk-target="card-7"]')!)
      break;
    case StateMachine.Steps.Step8:
      showElement(root.querySelector('[dk-target="card-8"]')!)
      break;
    case StateMachine.Steps.Step9:
      showElement(root.querySelector('[dk-target="card-9"]')!)
      break;
    case StateMachine.Steps.Complete:
      showElement(root.querySelector('[dk-target="card-10"]')!)
      break;
    default:
      throw Error("You've reached the end of the internet. Please turn back.")
  }
}

export const disableNextButton = (element: HTMLElement) => {
  element.setAttribute('disabled', '')
  element.classList.add('disabled')
}

export const enableNextButton = (element: HTMLElement) => {
  element.removeAttribute('disabled')
  element.classList.remove('disabled')
}

export const watchCheckboxes = (root: RootElement) => {
  const checkboxes = Array.from(root.querySelectorAll<HTMLInputElement>('[dk-target="pre-test-checkbox"] input[type="checkbox"]'))
  checkboxes.forEach((checkbox) => {
    const parentCard = checkbox.closest('[dk-target^="card-"]')
    if (!parentCard) return
    const nextButton = parentCard.querySelector<HTMLElement>('[dk-action="next:click"]')
    if (!nextButton) return

    if (checkbox.checked) {
      enableNextButton(nextButton)
    } else {
      disableNextButton(nextButton)
    }

    checkbox.addEventListener('change', function () {
      if (checkbox.checked) {
        enableNextButton(nextButton)
      } else {
        disableNextButton(nextButton)
      }
    })
  })
}

export const watchQuestionnaireForCompletion = (root: RootElement, skip$: BehaviorSubject<boolean>) => {
  const questionsForm = forTarget<HTMLFormElement>(root, 'pre-test-questions')!
  if (!questionsForm) return
  const parentCard = questionsForm.closest('[dk-target^="card-"]')!
  if (!parentCard) return
  const nextButton = parentCard.querySelector<HTMLElement>('[dk-action="next:click"]')!
  if (!nextButton) return

  function dealWithNextButton () {
    const radioGroupCount = Array.from(questionsForm.querySelectorAll<HTMLElement>('fieldset')!).length
    const checkedRadios = Array.from(questionsForm.querySelectorAll<HTMLInputElement>('input[type="radio"]:checked')!)

    if (checkedRadios.length === radioGroupCount) {
      let skip = skip$.getValue()
      skip = Array.from(questionsForm.querySelectorAll<HTMLInputElement>('[value*="no"]:checked')).length === radioGroupCount
      skip$.next(skip)
      enableNextButton(nextButton)

    } else {
      disableNextButton(nextButton)
    }
  }

  dealWithNextButton()

  questionsForm.addEventListener('click', () => {
    dealWithNextButton()
  })
}

export const bypassEverything = (currentScreenState$: BehaviorSubject<StateMachine.Steps>) => {
  // add skip to hearing test button
  const skipButton = document.createElement('button')
  skipButton.innerText = 'Skip to hearing test'
  skipButton.classList.add('button')
  skipButton.setAttribute('style', 'position: fixed; top: 0; right: 0; z-index: 1000;')
  document.body.appendChild(skipButton)

  // when the button is clicked, skip to the hearing test (step 7)
  skipButton.addEventListener('click', () => {
    const hearingTestStep = StateMachine.Steps.Step7
    currentScreenState$.next(hearingTestStep)
  })

  // remove the test gate modal
  document.getElementById('test-gate-modal')?.remove()
}
