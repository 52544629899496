import { BehaviorSubject } from 'rxjs'

const isOpenSubject = new BehaviorSubject(false)
export const isOpen$ = isOpenSubject.asObservable()

export function toggleDropdown() {
  isOpenSubject.next(!isOpenSubject.value)
}

export function openDropdown() {
  isOpenSubject.next(true)
}

export function closeDropdown() {
  isOpenSubject.next(false)
}
