import { fromTemplate } from '@core/template'
import axios from 'axios'

import {
  FILTER_TARGET_KEY,
  SEARCH_URL,
  SINGLE_SELECT_ITEM_GROUP_KEY,
  FILTER_COLLECTION_KEY,
  DK_TEMPLATE_KEY,
  DK_VALUE,
  DK_HIDDEN_VALUE,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT,
  DK_SEARCH_FORM,
  DK_SEARCH_FIELDS,
  DK_SEARCH_INPUT
} from './filters/FilterConstants'
import { FilterItem } from './filters/FilterItem'
import { FilterCollection } from './filters/FilterCollection'
import { FilterState } from './filters/FilterState'

export default class Filters {
  private state: FilterState

  constructor () {
    this.state = new FilterState(this.filterCollection.key)
    this.initializeSetup()
  }

  private initializeSetup () {
    this.filterCollection.items.forEach((item: FilterItem) => {
      item.addSelectListener(this.handleFilterSelect)
    })

    this.filterCollection.resetFilterItems.forEach((item: FilterItem) => {
      item.addSelectListener(this.handleFilterReset)
    })

    this.filterCollection.sortFilterItems.forEach((item: FilterItem) => {
      item.addSelectListener(this.handleFilterSort)
    })

    this.addStateFields()

    this.addDefaultSortFields()

    this.addSearchListener()
  }

  private handleFilterSelect = (event: Event) => {
    const item = new FilterItem(event.target as HTMLElement)
    const itemArgs = { slug: item.slug, value: item.value }
    const selectGroup = item.element?.closest(`[${SINGLE_SELECT_ITEM_GROUP_KEY}]`) as HTMLElement

    if (selectGroup) {
      this.resetSelectGroup(selectGroup)
      if (item.element?.nodeName === 'INPUT' && item.element.getAttribute('type') === 'checkbox') {
        (item.element as HTMLInputElement).checked = true
      }
    }

    if (this.state.filtersIncludes(itemArgs)) {
      this.state.removeFilter(itemArgs)
    } else {
      this.handleFilterAdd(item)
    }

    this.handleFiltering()
  }

  private resetSelectGroup (group: HTMLElement) {
    const selectCollection = new FilterCollection(group)
    selectCollection.items.forEach((item: FilterItem) => {
      item.resetItemSelection()
      this.state.removeFilter({ slug: item.slug, value: item.value })
    })
  }

  private handleFilterAdd (item: FilterItem) {
    const itemArgs = { slug: item.slug, value: item.value }
    if (this.singleSelectItemGroup(item)) {
      this.state.resetFilter(item.slug)
    }

    this.state.addFilter(itemArgs)
  }

  private addStateFields = () => {
    const templateKey = this.filterCollection.templateKey
    const template = document.querySelector(`[${DK_TEMPLATE_KEY}=${templateKey}]`)

    if (!template) return

    const values = template.querySelectorAll(`[${DK_VALUE}]`)
    const hiddenValues = template.querySelectorAll(`[${DK_HIDDEN_VALUE}]`)
    const fields = [...Array.from(values), ...Array.from(hiddenValues)].map((node) => {
      return node.getAttribute(DK_VALUE) || node.getAttribute(DK_HIDDEN_VALUE)
    }).filter(f => !!f)

    this.state.addFields(fields as string[])
  }

  private addDefaultSortFields = () => {
    const slug = document.querySelector(`[${DEFAULT_SORT}]`)?.getAttribute(DEFAULT_SORT)
    const direction = document.querySelector(`[${DEFAULT_SORT_DIRECTION}]`)?.getAttribute(DEFAULT_SORT_DIRECTION)
    this.state.addSort(slug, direction)
  }

  // Need to clean this up should have a `filterSearchForm` object probably that abstracts this away
  private addSearchListener = () => {
    const searchForms = Array.from(document.querySelectorAll(`[${DK_SEARCH_FORM}]`))
    if (!searchForms) return

    searchForms.forEach((searchForm) => {
      const searchInput = searchForm.querySelector(`[${DK_SEARCH_INPUT}]`) as HTMLInputElement
      const searchFields = searchForm.getAttribute(DK_SEARCH_FIELDS)?.split(';') || []

      searchForm.addEventListener('submit', (e) => {
        e.preventDefault()
        this.state.addSearchFields(searchFields)
        this.state.addSearchValue(searchInput?.value)
        this.handleFiltering()
      })
    })
  }

  private handleFilterReset = () => {
    this.filterCollection.items.forEach((item: FilterItem) => item.resetItemSelection())
    this.filterCollection.sortFilterItems.forEach((item: FilterItem) => item.resetItemSelection())
    // Need to clean this up should have a `filterSearchForm` object probably that abstracts this away
    Array.from(document.querySelectorAll(`[${DK_SEARCH_INPUT}]`)).forEach((input: Element) => {
      (input as HTMLInputElement).value = ''
    })
    this.state.resetState()
    this.addStateFields()
    this.addDefaultSortFields()
    this.handleFiltering()
  }

  private handleFilterSort = (event: Event) => {
    const item = new FilterItem(event.target as HTMLElement)
    this.filterCollection.sortFilterItems.forEach((sortItem: FilterItem) => {
      if (sortItem.element === item.element) return
      sortItem.resetItemSelection()
    })

    if (this.state.currentlySortedBy(item.slug, item.sortDirection)) {
      this.state.resetSort()
      this.addDefaultSortFields()
    } else {
      this.state.addSort(item.slug, item.sortDirection)
    }

    this.handleFiltering()
  }

  private singleSelectItemGroup (item: FilterItem) {
    if (!this.filterCollection?.element) return false
    const elements: Element[] = Array.from(
      this.filterCollection.element?.querySelectorAll(`[${SINGLE_SELECT_ITEM_GROUP_KEY}]`),
    )

    return elements.some((element) => {
      return element.getAttribute(SINGLE_SELECT_ITEM_GROUP_KEY) === item.slug
    })
  }

  public handleFiltering = async () => {
    const { data } = await this.filterRequest()
    this.updateDOM(data)
  }

  public filterRequest = async () => {
    return axios.post(SEARCH_URL!, this.state.state)
  }

  public updateDOM (items: []) {
    const filterTarget = document.querySelector(`[${FILTER_TARGET_KEY}]`)
    const template = this.filterCollection.templateKey
    if (!filterTarget || !template) return

    filterTarget.innerHTML = ''
    items.forEach((item) => {
      filterTarget.append(fromTemplate(template, item)!)
    })
  }

  public get filterCollection () {
    return new FilterCollection(
      document.querySelector(`[${FILTER_COLLECTION_KEY}]`),
    )
  }
}
