import { forTarget, hideElement, showElement } from '@core/content'
import { Splide } from '@splidejs/splide'

import * as ResultChart from './resultsChart'
import * as ResultTable from './resultsTable'

type Severity = 'normal' | 'mild' | 'moderate' | 'severe'
type SeverityBoolean = 'normal' | 'someLoss'

export const frequencyTiers = [250, 500, 1000, 2000, 4000, 8000]

let finalSlideSplide: Splide

export const buildSplide = (root: RootElement) => {
  const finalSlideSplideTarget = forTarget<HTMLElement>(root, 'splide-hearing-test')!
  const list = finalSlideSplideTarget.querySelector('.splide__list')
  if (list && list.childNodes.length > 1) {
    finalSlideSplide = new Splide(finalSlideSplideTarget as HTMLElement, {
      gap: 10,
      perPage: 1,
      mediaQuery: 'min',
      breakpoints: {
        992: {
          destroy: true,
        },
      },
    }).mount()
  }
}

export const displayChart = (root: RootElement) => {
  const form = document.getElementById('hearing-test-form')!
  const inputsLeft = Array.from(form.querySelectorAll<HTMLInputElement>('input[id^="L"]')!)
  const inputsRight = Array.from(form.querySelectorAll<HTMLInputElement>('input[id^="R"]')!)
  const inputsArrayLeft: number[] = inputsLeft.map((input) => parseInt(input.value))
  const inputsArrayRight: number[] = inputsRight.map((input) => parseInt(input.value))
  const shopTile = forTarget<HTMLElement>(root, 'results-shop')!

  const resultsSummary: Record<Severity, HTMLElement> = {
    normal: forTarget<HTMLElement>(root, 'results-summary-normal')!,
    mild: forTarget<HTMLElement>(root, 'results-summary-mild')!,
    moderate: forTarget<HTMLElement>(root, 'results-summary-moderate')!,
    severe: forTarget<HTMLElement>(root, 'results-summary-severe')!,
  }

  const textDisplay: Record<SeverityBoolean, HTMLElement> = {
    normal: forTarget<HTMLElement>(root, 'results-normal')!,
    someLoss: forTarget<HTMLElement>(root, 'results-some-loss')!,
  }

  const hideAllSummaries = () => {
    Object.values(resultsSummary).forEach((summary) => hideElement(summary))
  }

  const handleDisplayChanges = (db: number) => {
    hideAllSummaries()

    if (db <= 20) {
      showElement(resultsSummary.normal)
      showElement(textDisplay.normal)
      hideElement(textDisplay.someLoss)
      hideElement(shopTile)

      // hide the shop tile, as we don't recommend hearing aids for normal hearing
      shopTile.classList.remove('splide__slide')

      // refresh the splide with new slide count
      finalSlideSplide.refresh()
    } else {
      showElement(textDisplay.someLoss)
      hideElement(textDisplay.normal)
      showElement(shopTile)

      // ensure the shop tile is visible, and refresh the splide
      if (!shopTile.classList.contains('splide__slide')) {
        shopTile.classList.add('splide__slide')
      }

      // refresh the splide with new slide count
      finalSlideSplide.refresh()

      if (db <= 40) {
        showElement(resultsSummary.mild)
      } else if (db <= 60) {
        showElement(resultsSummary.moderate)
      } else {
        showElement(resultsSummary.severe)
      }
    }
  }

  const maxDbLeft = Math.max(...inputsArrayLeft)
  const maxDbRight = Math.max(...inputsArrayRight)
  const maxDbOverall = Math.max(maxDbLeft, maxDbRight)

  handleDisplayChanges(maxDbOverall)

  updateResultsData(inputsLeft, inputsRight)
  ResultChart.updateResultsChart(inputsArrayLeft, inputsArrayRight)
  ResultTable.updateResultsTable(inputsLeft, inputsRight, frequencyTiers)
}

const updateResultsData = (dataLeft: ArrayLike<HTMLInputElement>, dataRight: ArrayLike<HTMLInputElement>) => {
  const testResults: Record<string, { left: number; right: number }> = {}
  Array.from(dataLeft).forEach((dataPoint) => {
    const frequency = dataPoint.name.slice(2)
    testResults[frequency] = { left: parseInt(dataPoint.value), right: 20 }
  })
  Array.from(dataRight).forEach((dataPoint) => {
    const frequency = dataPoint.name.slice(2)
    testResults[frequency].right = parseInt(dataPoint.value)
  })

  localStorage.setItem('testResults', JSON.stringify(testResults))
}
