type FilterType = {
  slug: string,
  values: string[]
}

type SearchType = {
  value: string,
  fields: string[]
}

type StateType = {
  collection: null | string | undefined
  filters: FilterType[]
  fields: string[]
  search: SearchType | {}
  sortKey: null | string
  sortDirection: null | string
}

interface FilterQuery {
  slug: null | undefined | string
  value: null | undefined | string
}

export class FilterState {
  private collection: string
  private _state: StateType | undefined

  constructor (collection: string) {
    this.collection = collection
    this.state = this.initialState(this.collection)
  }

  filtersIncludes = ({ slug, value }: FilterQuery) => {
    return this.state.filters.some((filter) => {
      if (filter.slug !== slug) return false

      return filter.values.some((val) => val === value)
    })
  }

  removeFilter = ({ slug, value }: FilterQuery) => {
    const newFilterState: FilterType[] = []
    this.state.filters.forEach((filter) => {
      if (filter.slug !== slug) {
        newFilterState.push(filter)
      } else {
        const newFilterValues = filter.values.filter((val) => {
          return val !== value
        })

        if (newFilterValues.length > 0) {
          newFilterState.push({ ...filter, values: newFilterValues })
        }
      }
    })

    this.state.filters = newFilterState
  }

  resetState () {
    this.state = this.initialState(this.collection)
  }

  resetFilter (slug: null | undefined | string) {
    const newFilterState: FilterType[] = []
    this.state.filters.forEach((filter) => {
      if (filter.slug !== slug) {
        newFilterState.push(filter)
      }
    })

    this.state.filters = newFilterState
  }

  addFilter = ({ slug, value }: FilterQuery) => {
    if (!value || !slug) return

    const newFilterState: FilterType[] = []
    let foundFilter = false
    this.state.filters.forEach((filter) => {
      if (filter.slug === slug) {
        foundFilter = true
        newFilterState.push({ ...filter, values: [...filter.values, value] })
      } else {
        newFilterState.push(filter)
      }
    })

    if (!foundFilter) {
      newFilterState.push({ slug, values: [value] })
    }

    this.state.filters = newFilterState
  }

  currentlySortedBy = (sortKey: string | null = null, sortDirection: null | string = null) => {
    return this.state.sortKey === sortKey && this.state.sortDirection === sortDirection
  }

  resetSort = () => {
    this.state = {
      ...this.state,
      sortKey: null,
      sortDirection: null,
    }
  }

  addSort = (sortKey: string | null = null, sortDirection: null | string = null) => {
    this.state = {
      ...this.state,
      sortKey,
      sortDirection,
    }
  }

  addFields = (fields: string[]) => {
    this.state = {
      ...this.state,
      fields
    }
  }

  addSearchFields = (fields: string[]) => {
    this.state = {
      ...this.state,
      search: {
        ...this.state.search,
        fields
      }
    }
  }

  addSearchValue = (value: string) => {
    this.state = {
      ...this.state,
      search: {
        ...this.state.search,
        value
      }
    }
  }

  private initialState (collection: string) {
    return {
      collection: collection,
      filters: [],
      search: {},
      fields: [],
      sortKey: null,
      sortDirection: null,
    }
  }

  get state () {
    return this._state!
  }

  private set state (state) {
    this._state = state
  }

  private get filters () {
    return this.state.filters
  }
}
