import { forTarget } from '@core/content'
import { gqlQuery } from '@core/gqlQuery'
import { retry } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'

export type ProductsObservable = BehaviorSubject<Record<string, any>>

export type ProductDataFromLink = {
  slug: string
  name: string
}

export const ProductByPKQuery = `#graphql
query ProductByPK ($pk: String!) {
  product: productByPK(pk: $pk) {
    id
    name
    slug
    rechargeable
    bluetooth
    price
    inEar
    behindEar
    typeOfHearingLoss
    mainImage
    productVariantStripeText
    comparisonVariantStripeText
    ratingCount
    ratingAverage
    audioFileBackgroundNoise
    audioFileQuietTV
    audioFileMusicStreaming
    scorecard {
      id
      hearingLossLevel
      careModel
      soundQuality
      comfort
      style
      battery
      connection
      dexterityLevel
      waterproofLevel
      appFeatures
      hearingLossLevelRichText
      careModelRichText
      soundQualityRichText
      comfortRichText
      styleRichText
      batteryRichText
      connectionRichText
      dexterityLevelRichText
      waterproofLevelRichText
      appFeaturesRichText
    }
  }
}
`

/**
 * setProductComparisonData
 *
 * @param event
 * @param products$
 */
export function setProductComparisonData (event: CustomEvent, products$: ProductsObservable) {
  event.preventDefault()

  const target = event.currentTarget! as HTMLLinkElement
  const dataset = forTarget<HTMLSpanElement>(target, 'product-information')!.dataset as ProductDataFromLink
  const currentValue = products$.getValue() as Record<string, ProductDataFromLink>

  if (currentValue[dataset.slug]) {
    delete currentValue[dataset.slug!]
    products$.next({ ...currentValue })
  } else {
    addProductComparisonData(dataset, products$)
  }
}

/**
 * addProductComparisonData
 *
 * adds a product to the comparison but limits it to only 4 products
 *
 * @param dataset
 * @param products$
 */
function addProductComparisonData (dataset: ProductDataFromLink, products$: ProductsObservable) {
  const currentValue = products$.getValue()

  gqlQuery({ query: ProductByPKQuery, variables: { pk: dataset.slug } })
    .pipe(retry(2))
    .subscribe(({ response }) => {
      const { product } = response.data
      const values: ProductDataFromLink[] = Object.values(currentValue)
      const count = 4

      if (values.length === count) {
        values[count - 1] = product
      } else {
        values.push(product)
      }

      const results = values.reduce((acc: Record<string, any>, curr) => {
        acc[curr.slug] = curr
        return acc
      }, {})

      products$.next(results)
    })
}

/**
 * toggleProductSelection
 *
 * @param link
 * @param dataset
 * @param products$
 */
export function toggleProductSelection (productElement: HTMLElement, products$: ProductsObservable) {
  products$.subscribe((products) => {
    const img = forTarget<HTMLImageElement>(productElement, 'selection-toggle-image')!
    const dataset = productElement.querySelector<HTMLElement>('[data-slug]')?.dataset as Record<string, any>
    const link = productElement.querySelector('a[dk-action="compare-product:click"]')!
    const productName = productElement.querySelector<HTMLElement>('[data-name]')?.getAttribute('data-name')

    if (!img || !dataset || !link) return

    if (products[dataset?.slug]) {
      link.setAttribute('aria-label', `Remove ${productName} from comparison`)
    } else {
      link.setAttribute('aria-label', `Add ${productName} to comparison`)
    }

    img.src = products[dataset?.slug]
      ? 'https://uploads-ssl.webflow.com/624750193b887ddb06ea64ee/62630780111fd424ded47845_CheckMark.svg'
      : 'https://uploads-ssl.webflow.com/624750193b887ddb06ea64ee/626307808b572f730960e8f6_Plus.svg'
  })
}
