import { BehaviorSubject } from 'rxjs'
import { ComponentActions } from '@core/bootstrap'
import { forTarget } from '@core/content'
import { mount } from '@core/mount'
import { on } from '@core/on'

import type { ProductTracks } from './AudioComparisonShared'
import {
  AudioState,
  audioPlayer,
  audioState$,
  buildAudioPlayer,
  controlTracks,
  decorateButton,
  disableMusicStreaming,
  displayDropdown,
  handleButtonPress,
} from './AudioComparisonShared'

let productTracks: Record<string, ProductTracks> = {}

export default function AudioComparisonProductDetail (root: RootElement): ComponentActions {
  let currentProductSlug: string
  let currentProductName: string
  let currentTrackName = 'backgroundNoise'
  let matchedTime = 0

  const currentProduct$ = new BehaviorSubject<string | null>(null)

  /**
   * getSingleProductData
   *
   * This is the single-product version of buildListOfProductTracks in AudioComparison.
   * It builds a productTracks object containing any audio tracks that this product has.
   *
   * @param root
   */
  function getSingleProductData (root: RootElement) {
    const dataElement = forTarget<HTMLDivElement>(root, 'product-data')

    if (!dataElement) {
      console.warn('Missing element with dk-target="product-data"')
      return
    }

    const getElementValue = (attributeName: string): string =>
        dataElement.querySelector<HTMLElement>(`[dk-target="product-${attributeName}"]`)!.getAttribute('dk-value')!

    const name = getElementValue('name')
    const slug = getElementValue('slug')
    const backgroundNoise = getElementValue('backgroundNoise')
    const quietTV = getElementValue('quietTV')
    const musicStreaming = getElementValue('musicStreaming')

    // disable unneeded productButtons in dropdown
    if (!backgroundNoise) root.querySelector<HTMLButtonElement>('[dk-action="dropdownButton:click"][dk-value="product.backgroundNoise"]')?.setAttribute('disabled', '')
    if (!quietTV) root.querySelector<HTMLButtonElement>('[dk-action="dropdownButton:click"][dk-value="product.quietTV"]')?.setAttribute('disabled', '')
    // completely remove musicStreaming list item if the product doesn't have that track type
    if (!musicStreaming) root.querySelector<HTMLElement>('.dropdown-item:nth-child(3)')?.remove()

    // if no tracks at all, hide the whole component
    if (!backgroundNoise && !quietTV && !musicStreaming) {
      (root as HTMLElement).setAttribute('style', 'display: none;')
    }

    currentProductSlug = slug
    currentProduct$.next(currentProductSlug)
    currentProductName = name

    productTracks = {
      [slug]: {
        backgroundNoise: backgroundNoise,
        quietTV: quietTV,
        musicStreaming: musicStreaming,
      },
    }

    // update dropdown productButtons dk-slug to use currentProductSlug
    Array.from(root.querySelectorAll<HTMLButtonElement>('[dk-action="dropdownButton:click"][dk-value^="product"]')).forEach((dropdownProductButton) => {
      dropdownProductButton.setAttribute('dk-slug', `${currentProductSlug}`)
      dropdownProductButton.setAttribute('dk-name', `${currentProductName}`)
    })
  }

  /**
   * updatePlayer
   *
   * Sets the audio source based on the button click, sets the current time
   * of the audio player, and finally plays the audio.
   *
   * @param track
   * @returns
   */
  function updatePlayer (track: AudioState) {
    if (track === AudioState.off) return

    const [audioType, trackName] = track.split('.')

    // if we're trying to play a new track with the same name,
    // record the point at which we stopped the original track
    if (currentTrackName === trackName) {
      matchedTime = audioPlayer.currentTime
    } else {
      matchedTime = 0
    }

    // set audioPlayer's src
    if (audioType === 'control') {
      audioPlayer.src = controlTracks[trackName]
    } else if (audioType === 'product') {
      // @ts-ignore
      audioPlayer.src = productTracks[currentProductSlug][trackName]
    }

    // update currentTrackName, so we can check it on the next button press
    currentTrackName = trackName

    // set audioPlayer's currentTime
    audioPlayer.currentTime = matchedTime

    audioPlayer.play()
  }

  return {
    watch: [
      {
        target: currentProduct$,
        actions: [
          handleButtonPress,
        ],
      },
      {
        target: audioState$,
        actions: [
          updatePlayer,
        ],
      },
    ],
    start:
      mount(
        root,
        displayDropdown,
        buildAudioPlayer,
        getSingleProductData,
        disableMusicStreaming,
        on('dropdownButton', (el) => {
          currentProduct$.next(el.target.getAttribute('dk-value')!)
          decorateButton(el.target)
        }),
      ),
  }
}
