import { applyStyles } from '@lib/applyStyles'
import { assertNonNullish } from '@lib/assert'
import { BehaviorSubject } from 'rxjs'
import { clearContents, fromTemplate } from '@core/template'
import { ComponentActions } from '@core/bootstrap'
import { forTarget, hideElement, showElement } from '@core/content'
import { gqlQuery } from '@core/gqlQuery'
import { mount } from '@core/mount'
import { on } from '@core/on'
import DialogCreator from '@modules/dialog'
import { setRatingStars } from '@modules/ratingStars'

type AudiologistProfile = Audiologists & AudiologistReview

export default function AudiologistProfile(root: RootElement, _props: any): ComponentActions {
  const profileID$ = new BehaviorSubject<Maybe<string>>(null)
  const profile$ = new BehaviorSubject<Maybe<Audiologists>>(null)
  const reviews$ = new BehaviorSubject<Maybe<AudiologistReview>>(null)
  const MAX_REVIEW_LENGTH = 100

  const audiologistQuery = `#graphql
    query AudiologistByPK($pk: String) {
      audiologist: audiologistByPK(pk: $pk) {
        name
        about
        address
        city
        state
        zip
        rating
        latitude
        longitude
        reviewCount
        website
        mapsUrl
        formattedPhoneNumber
        description
      }
    }
  `

  const reviewQuery = `#graphql
    query Reviews ($pk: String) {
      audiologist: audiologistReviews(pk: $pk) {
        openingHours {
          openNow
          weekdayText
        }
        photos {
          photoReference
        }
        reviews {
          relativeTimeDescription
          authorName
          authorUrl
          language
          profilePhotoUrl
          rating
          text
        }
      }
    }
  `

  /**
   * createMapsLink
   *
   * @param profile
   */
  function createMapsLink(profile: Audiologists) {
    return `//maps.apple.com/?q=${encodeURIComponent(profile.name)}&sll=${profile.latitude},${profile.longitude}`
  }

  /**
   * checkProfileID
   *
   * if the url for an id in the search param.
   * if present, it sets the profileID observable.
   * if not, it redirects the user to the audiologists search page
   */
  function checkProfileID(): void {
    try {
      const params = new URLSearchParams(window.location.search)
      const id = params.get('id')

      assertNonNullish<string>(id, 'no id set')
      profileID$.next(id)
    } catch (error) {
      console.error(error)
      window.location.pathname = '/audiologists/results'
    }
  }

  /**
   * fetchProfileData
   *
   * fetches the profile data for an audiologists based on their slug
   *
   * @param pk
   */
  function fetchProfileData(pk: Maybe<string>): void {
    gqlQuery({
      query: audiologistQuery,
      variables: { pk },
    }).subscribe(({ response }) => {
      profile$.next(response.data.audiologist)
    })
  }

  /**
   * fetchReviews
   *
   * Fetches Google business reviews for an audiologist and passes it to the reviews$ BehaviourSubject
   * @param pk
   */
  function fetchReviews(pk: Maybe<string>): void {
    gqlQuery({
      query: reviewQuery,
      variables: { pk },
    }).subscribe(({ response }) => {
      reviews$.next(response.data.audiologist)
    })
  }

  /**
   * mergeData
   *
   * merges the Audiologist and AudiologistReviewData into one value
   */
  function mergeData(): Maybe<AudiologistProfile> {
    const profile = profile$.getValue()
    const reviews = reviews$.getValue()

    if (!profile || !reviews) return

    return { ...profile, ...reviews }
  }

  /**
   * setPageTitle
   *
   * @param profile
   */
  async function setPageTitle(profile: AudiologistProfile): Promise<void> {
    if (!profile) return
    document.title = `${profile.name} | Soundly`
  }

  /**
   * hoursForToday
   *
   * Gets the set of hours for today
   *
   * @param weekdayHours
   */
  function hoursForToday(weekdayHours: string[]): Maybe<string> {
    const lexicon = [/sunday/i, /monday/i, /tuesday/i, /wednesday/i, /thursday/i, /friday/i, /saturday/i]
    return weekdayHours?.find((hours) => hours.match(lexicon[new Date().getDay()])) || null
  }

  /**
   * addHoursToList
   *
   * @param profile
   * @param container
   */
  function addHoursToList(profile: Audiologists & AudiologistReview, container: HTMLElement) {
    if (!profile?.openingHours?.weekdayText) return

    for (const hours of profile.openingHours.weekdayText) {
      const li = document.createElement('li')!
      li.innerText = hours
      container.appendChild(li)
    }
  }

  /**
   * extendHoursContainer
   *
   * @param hours
   * @param toggle$
   * @param hoursContainer
   * @param toggleButton
   */
  function extendHoursContainer(
    hours: Element,
    toggle$: BehaviorSubject<boolean>,
    hoursContainer: HTMLElement,
    toggleButton: HTMLButtonElement,
  ) {
    const chevron = document.createElement('img')
    chevron.setAttribute('alt', '')
    applyStyles(chevron, { marginLeft: '0.5rem', height: '1.6rem', position: 'relative', top: '-0.2rem' })
    hours.appendChild(chevron)

    toggle$.subscribe((state) => {
      if (state) {
        showElement(hoursContainer)
        chevron.src =
          'https://uploads-ssl.webflow.com/624750193b887ddb06ea64ee/6287b7a557a1c90fcaa47842_chevron-down.svg'
        toggleButton.setAttribute('aria-expanded', 'true')
      } else {
        hideElement(hoursContainer)
        chevron.src =
          'https://uploads-ssl.webflow.com/624750193b887ddb06ea64ee/6287b7a66082812d74652e81_chevron-right.svg'
        toggleButton.setAttribute('aria-expanded', 'false')
      }
    })
  }

  /**
   * handleHoursView
   *
   * handles displaying and toggling the hours portion of the profile page
   *
   * @param elem
   * @param profile
   */
  function handleHoursView(elem: HTMLElement, profile: AudiologistProfile) {
    const hoursContainer = forTarget<HTMLElement>(elem, 'hours-list')!
    addHoursToList(profile, hoursContainer)
    const hours = elem.querySelector('[dk-value="hours"]')!
    const toggleButton = forTarget<HTMLButtonElement>(elem, 'hours-toggle-button')!
    const toggle$ = new BehaviorSubject<boolean>(false)
    on('toggle-times', () => toggle$.next(!toggle$.getValue()))(elem)

    extendHoursContainer(hours, toggle$, hoursContainer, toggleButton)
  }

  /**
   * setLearnMoreLink
   *
   * @param profile
   */
  function setLearnMoreLink(profile: AudiologistProfile) {
    if (!profile) return
    const link = forTarget<HTMLLinkElement>(root, 'website-learn-more')!

    if (!profile.website) {
      hideElement(link)
    }

    link.href = profile.website
    link.target = '_blank'
  }

  /**
   * displayAdditionalProperties
   *
   * @param profile
   * @param elem
   */
  function displayAdditionalProperties(profile: AudiologistProfile, elem: HTMLElement) {
    const link = forTarget<HTMLLinkElement>(elem, 'website')!
    if (!profile.website) hideElement(link)
    link.href = profile.website

    forTarget<HTMLLinkElement>(elem, 'directions')!.href = profile.mapsUrl
    forTarget<HTMLLinkElement>(elem, 'phone')!.href = `tel:+1${profile.formattedPhoneNumber}`
  }

  /**
   * populateReviewModal
   *
   * @param review
   * @param modal
   */
  function populateReviewModal(review: Review, modal: HTMLElement) {
    const text = modal.querySelector('[dk-value="text"]')!
    text.innerHTML = review.text

    const author = modal.querySelector('[dk-value="authorName"]')!
    author.innerHTML = review.authorName
  }

  /**
   * handleLongReviews
   *
   * @param review
   * @param elem
   */
  function handleLongReviews(review: Review, elem: HTMLElement) {
    const isLong = review.text.length > MAX_REVIEW_LENGTH
    const truncatedText = review.text.substring(0, MAX_REVIEW_LENGTH) + (isLong ? '...' : '')

    if (isLong) {
      const reviewContainer = elem.querySelector('[dk-value="text"]')!
      const reviewModal = document.getElementById('review-modal')!

      // magic trick to make the code wait a beat before grabbing all the modal triggers
      setTimeout(DialogCreator, 0)

      const link = forTarget<HTMLElement>(elem, 'read-more')!
      link.setAttribute('aria-label', `Read more from reviewer ${review.authorName}`)
      showElement(link)
      link.addEventListener('click', (event) => {
        event.preventDefault()
        // Implement a magical modal that displays unicorns and reviews when clicked.
        // Since we already have a magical modal, we just need to replace the modal content.
        populateReviewModal(review, reviewModal)
      })

      reviewContainer.innerHTML = truncatedText
    }
  }

  /**
   * appendReviews
   *
   * cycles through an audiologist's reviews and appends them to the page
   * @param profile
   */
  function addReviews(profile: AudiologistProfile) {
    const container = forTarget<HTMLDivElement>(root, 'reviews-container')!
    clearContents(container)

    for (const review of profile.reviews) {
      const elem = fromTemplate('review', review)!
      handleLongReviews(review, elem)

      // add review stars
      const starsGroup = forTarget<HTMLDivElement>(elem, 'rating-stars')
      if (!starsGroup) return
      starsGroup.setAttribute('dk-value', String(review.rating))
      setRatingStars(starsGroup)

      container.append(elem)
    }
  }

  /**
   * renderProfile
   *
   * populates the profile template and appends it to the page
   */
  function renderProfile(): void {
    try {
      const profile = mergeData()
      if (!profile) return
      profile.mapsUrl = createMapsLink(profile)
      profile.open = profile.openingHours?.openNow ? 'Open' : 'Closed'
      profile.hours = hoursForToday(profile.openingHours?.weekdayText)

      // remove the default click here text
      if (profile.about.match(/click for more/i)) profile.about = ''

      const elem = fromTemplate('profile', profile)!

      handleHoursView(elem, profile)
      displayAdditionalProperties(profile, elem)
      profile.reviews && addReviews(profile)

      // Add review counts
      // TODO: This has been used in 3 places so far, great time to extract it into the stars component
      const starsGroup = forTarget<HTMLDivElement>(elem, 'rating-stars')!
      starsGroup.setAttribute('dk-value', String(profile.rating))
      setRatingStars(starsGroup)

      forTarget<HTMLDivElement>(root, 'profile-container')!.append(elem)
    } catch (error) {
      // console.log('****** Error in renderProfile ******')
      console.error(error.message)
    }
  }

  /**
   * displayMap
   *
   * Displays Google Maps using Wichita Kansas as the focal point and zoomed out to display the entire US.
   *
   * @param profile
   */
  async function displayMap(profile: Audiologists): Promise<void> {
    const container = forTarget<HTMLDivElement>(root, 'street-view')
    if (!container || !profile) return
    const { latitude: lat, longitude: lng } = profile

    // eslint-disable-next-line no-new
    const map = new google.maps.Map(container, {
      center: { lat, lng },
      fullscreenControl: false,
      disableDefaultUI: true,
      zoom: 11.5,
    })

    // eslint-disable-next-line no-new
    const marker = new google.maps.Marker({
      position: { lat, lng },
      clickable: true,
      map,
    })

    marker.addListener('click', () => {
      window.location.href = createMapsLink(profile)
    })
  }

  return {
    watch: [
      { target: profileID$, actions: [fetchProfileData, fetchReviews] },
      { target: reviews$, actions: [renderProfile] },
      { target: profile$, actions: [renderProfile, displayMap, setLearnMoreLink, setPageTitle] },
    ],
    start: mount(root, checkProfileID),
  }
}
