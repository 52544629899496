import { BehaviorSubject, isObservable, Observable, Subject } from 'rxjs'
import messages from '../lib/messages'

export type Props = Record<string, any>

type Watchable = {
  target: BehaviorSubject<Maybe<any>> | Subject<any> | Observable<any>
  actions: ((data: any) => any)[]
}

export type ComponentActions = {
  start: () => void
  onload?: () => void
  onunload?: () => void
  watch?: Watchable[]
}

export type Component = (root: HTMLElement, props: Props) => ComponentActions

/**
 * Calls onLoad for each module allowing you to immediately invoke action when
 * the module is bootstrapped
 g
 * @param props
 * @param components
 * @returns
 */
function handleOnload(props: Props, components: Component[]) {
  if (!components?.length) return

  ;[...components].forEach((component) => {
    const mod = component(document as unknown as HTMLElement, props)
    mod.onload && mod.onload()
  })
}

function registerSubscribers(component: ComponentActions) {
  try {
    component.watch?.forEach(({ target, actions }: Watchable) => {
      if (isObservable(target)) {
        return [...actions].forEach((action) => target.subscribe((data) => action(data)))
      }

      throw new Error(messages.bootstrap.invalidSubscriptionTarget)
    })
  } catch (error) {
    return console.error(error)
  }
}

/**
 *
 * @param element
 * @param components
 * @param props
 */
function loadModules(element: HTMLElement, components: Component[], props: Props) {
  components.forEach((component) => {
    const comp = component(element, props)
    comp.start()
    registerSubscribers(comp)
  })
}

/**
 * Loads a module and calls the start function with props as its parameters
 * IMPORTANT: Lazy is very experimental, use at your own risk
 *
 * @param app
 * @param components
 * @param props
 * @param lazy
 */
export function bootstrap(app: string, components: Component[] | any[], props: Props = {}, lazy = false): void {
  const containers = document.querySelectorAll(`[dk-component="${app}"]`)
  handleOnload(props, components)

  if (!containers) return

  if (lazy) {
    containers.forEach((container) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const moduleLoaded = container.getAttribute('loaded') === 'true'

          if (entry.isIntersecting && !moduleLoaded) {
            container.setAttribute('loaded', 'true')
            return loadModules(container as HTMLElement, components, props)
          }
        })
      }, {})

      observer.observe(container)
    })
  } else {
    containers.forEach((element) => {
      loadModules(element as HTMLElement, components, props)
    })
  }
}

/**
 * bootstrapAll
 *
 * Takes a configuration object and bootstraps each entry.
 * in order to apply lazy evaluation to an entry add :lazy to the key. e.g. log-in:lazy.
 *
 * @param config
 * @param props
 */
export function bootstrapAll<T>(config: Record<string, Component[]>, props: { [key in keyof T]: T[key] }) {
  Object.keys(config).forEach((key) => {
    const [app, lazy = false] = key.split(':')

    bootstrap(app, config[key], props, lazy === 'lazy')
  })
}
