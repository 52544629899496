import { mount } from '@core/mount'
import { Splide } from '@splidejs/splide'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  const buildSplide = () => {
    const list = root.querySelector('.splide__list')
    if (list && list.childNodes.length > 1) {
      new Splide(root as HTMLElement, {
        gap: 10,
        perPage: 1,
        mediaQuery: 'min',
        breakpoints: {
          992: {
            destroy: true,
          },
        },
      }).mount()
    }
  }

  return {
    start: mount(root, buildSplide),
  }
}
