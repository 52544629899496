import { forTarget } from '@core/content'

const resultsTable = document.createElement('table')

export const buildResultsTable = (root: RootElement) => {
  const testResultsParent = forTarget<HTMLElement>(root, 'test-results-table')!
  if (!testResultsParent) return console.warn('Missing dk-target="test-results-table"')
  resultsTable.classList.add('sr-only')
  testResultsParent.append(resultsTable)
}

export const updateResultsTable = (
  dataLeft: ArrayLike<HTMLInputElement>,
  dataRight: ArrayLike<HTMLInputElement>,
  frequencyTiers: number[],
) => {
  // build a fresh, empty table dynamically using frequencyTiers
  const buildEmptyTable = (tiers: number[]) => {
    const tbody = document.createElement('tbody')
    tiers.forEach((tier) => {
      const tr = document.createElement('tr')
      tr.classList.add(tier.toString())
      const td = document.createElement('td')
      td.classList.add('label')
      td.innerText = tier.toString()
      tr.appendChild(td)
      tbody.appendChild(tr)
    })
    return tbody.outerHTML
  }
  resultsTable.innerHTML = buildEmptyTable(frequencyTiers)

  function buildTableData (dataPoint: HTMLInputElement) {
    // create new table data element
    const td = document.createElement('td')

    // get the frequency, used in order to stick td in correct row
    const frequency = dataPoint.name.slice(2)

    // get the value, used to build inner content of td
    const value = parseInt(dataPoint.value)

    switch (true) {
      case (value <= 20):
        td.innerText = `${value.toString()}dB - Normal Hearing`
        break
      case (value <= 40):
        td.innerText = `${value.toString()}dB - Mild Hearing Loss`
        break
      case (value <= 60):
        td.innerText = `${value.toString()}dB - Moderate Hearing Loss`
        break
      case (value > 61):
        td.innerText = `${value.toString()}dB - Severe Hearing Loss`
        break
    }

    // stick this new td in the correct row for a given freq
    const matchingRow = resultsTable.querySelector(`tr[class="${frequency}"]`)!
    matchingRow.appendChild(td)
  }

  // build left cells first, so they will go in the correct column
  Array.from(dataLeft).forEach((dataPoint) => {
    buildTableData(dataPoint)
  })

  // build right cells second, so they will go in the correct column
  Array.from(dataRight).forEach((dataPoint) => {
    buildTableData(dataPoint)
  })
}
