import { convertTag } from './helpers'
import { isOpen$, toggleDropdown, openDropdown } from './navDropdownCustomService'

/**
 * NavDropdownsCustom
 *
 * This handles opening the Audio Comparison dropdown, which needs to stay open,
 * unlike "normal" dropdowns, which is why this is custom.
 */
export default function NavDropdownsCustom() {
  // Find and iterate over all dropdown toggles;
  // use them as the logical basis for managing dropdowns
  Array.from(document.querySelectorAll('[dk-nav-dropdown-toggle-custom]')).forEach((toggle) => {

    // Get the dropdown ID from the toggle's attribute
    let dropdownID = toggle.getAttribute('dk-nav-dropdown-toggle-custom')

    // If a valid dropdown ID is provided, find the target element and set appropriate roles
    if (!(dropdownID === '#') && !(dropdownID === null)) {
      let dropdown = document.getElementById(dropdownID)
      if (!dropdown) return

      // Set ARIA role attributes for a11y
      dropdown.setAttribute('role', 'menu')
      let menuLinks = dropdown.getElementsByTagName('a')
      Array.from(menuLinks).forEach((link) => {
        link.setAttribute('role', 'menuitem')
      })
    }

    // Modify the toggle element and set aria-expanded attribute for accessibility
    toggle = convertTag(toggle as HTMLElement, 'button')
    toggle.setAttribute('type', 'button')
    toggle.setAttribute('aria-expanded', 'false')

    // Get the dropdown parent element
    const dropdownParent = toggle.closest('[dk-nav-dropdown-parent]') || toggle.parentElement!

    // Helper function to "open" the dropdown
    function openThisDropdown() {
      toggle.setAttribute('aria-expanded', 'true')
      dropdownParent.classList.add('open')
    }

    // Helper function to "close" the dropdown
    function closeThisDropdown() {
      toggle.setAttribute('aria-expanded', 'false')
      dropdownParent.classList.remove('open')
    }

    // Handle click event on toggle button
    function handleToggleClick(event: Event) {
      event.preventDefault()
      toggleDropdown()
    }

    // Subscribe to isOpen$ and modify the dropdown based on its state
    isOpen$.subscribe((isOpen) => {
      if (!isOpen) {
        closeThisDropdown()
      } else {
        openThisDropdown()
      }
    })

    // Attach click listener to toggle
    toggle.addEventListener('click', (event) => {
      handleToggleClick(event)
    })

    // Find and attach click listeners to any dropdown close buttons
    Array.from(document.querySelectorAll('[dk-nav-dropdown-close]')).forEach((closer) => {
      const dropdownParent = closer.closest('[dk-nav-dropdown-parent]')
      if (!dropdownParent) return
      closer.addEventListener('click', function () {
        if (dropdownParent.classList.contains('open')) {
          closeThisDropdown()
        }
      })
    })

    // Handle interactions with "product" buttons
    function handleTheProductButtons (e: Event) {
      const el = (e.target as HTMLElement)!

      // Find the nearest official button element, which will have all the required attributes
      let productButtonClicked = el
      if (el.nodeName === 'BUTTON') {
        productButtonClicked = el
      }
      if (el.closest('button[dk-action="productButton:click"]') !== null) {
        productButtonClicked = el.closest('button[dk-action="productButton:click"]')!
      }

      // Using attributes from official button element, move focus to matching button in dropdown
      function setFocusOnCorrespondingButton () {
        const correspondingTrackName = productButtonClicked.getAttribute('dk-value')!
        const dropdownProductButton = document.querySelector<HTMLButtonElement>(`[dk-action="dropdownButton:click"][dk-value="${correspondingTrackName}"]`)
        dropdownProductButton?.focus()
      }

      isOpen$.subscribe((isOpen) => {
        if (!isOpen && el.closest('.comparison__button')!.getAttribute('aria-label') === 'play') {
          openDropdown()
          setFocusOnCorrespondingButton()
        } else if (isOpen) {
          setFocusOnCorrespondingButton()
        }
      })
    }

    const productButtons = Array.from(document.querySelectorAll<HTMLButtonElement>('[dk-action="productButton:click"]'))
    productButtons.forEach((button) => {
      button.addEventListener('click', handleTheProductButtons)
    })
  })
}
