import { createClient } from '@supabase/supabase-js'
import { decode } from 'base64-arraybuffer'
import Moment from 'moment'

export const saveToSupabase = async (callback?: (email: string, imageUrl: string) => Promise<void>) => {
  const SUPABASE_URL = process.env.SUPABASE_URL!
  const API_ANON_KEY = process.env.SUPABASE_ANON_KEY!

  const email = localStorage.getItem('email') ?? `test-${Moment().unix()}@test.com`
  const testResults = localStorage.getItem('testResults') ?? '{}'
  const imageBase64 = localStorage.getItem('imageBase64') ?? ''

  let imageUrl = null

  const timestamp = Math.floor(Date.now() / 1000)
  const hashName = (await hashEmail(email)) + '-' + timestamp

  // first we need to upload the image to the storage bucket
  try {
    const supabase = createClient(SUPABASE_URL, API_ANON_KEY)

    const prefix = 'data:image/png;base64,'
    const strBase64 = imageBase64.replace(prefix, '')

    const buf = decode(strBase64)

    const { data, error } = await supabase.storage.from('hearing-tests').upload(`${hashName}.png`, buf, {
      contentType: 'image/png',
    })

    if (data) {
      imageUrl = `${SUPABASE_URL}/storage/v1/object/public/hearing-tests/${data?.path}`
      localStorage.setItem('imageUrl', imageUrl)
    }

    if (error) {
      console.error(error)
    }
  } catch (error) {
    console.error('There was a problem with the storage POST fetch operation: ', error)
  }

  const bodyToSend = {
    email: email,
    results: JSON.parse(testResults),
    image_data_uri: imageBase64,
    hearing_test_image_url: imageUrl,
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      apikey: API_ANON_KEY,
    },
    body: JSON.stringify(bodyToSend),
  }

  try {
    const response = await fetch(`${SUPABASE_URL}/rest/v1/hearing_tests`, options)
    await callback?.(email, imageUrl!)

    return response
  } catch (error) {
    console.error(error)
  }
}

const hashEmail = async (email: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(email) // Encode the email to bytes
  const hashBuffer = await crypto.subtle.digest('SHA-256', data) // Hash the data
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // Convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // Convert bytes to hex
  return hashHex
}
