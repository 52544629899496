import { BehaviorSubject } from 'rxjs'
import * as StateMachine from './stateMachine'

export const handleNext = (
  _event: unused,
  _props: unused,
  element: HTMLButtonElement,
  skip$: BehaviorSubject<boolean>,
  audioPlayer: HTMLAudioElement,
  currentScreenState$: BehaviorSubject<StateMachine.Steps>,
) => {
  return StateMachine.performAction(
    skip$.getValue() ? StateMachine.ScreenActions.SkipTo : StateMachine.ScreenActions.Next,
    skip$,
    audioPlayer,
    currentScreenState$,
  )(_event, _props, element)
}

export const handlePrevious = (
  _event: unused,
  _props: unused,
  element: HTMLButtonElement,
  skip$: BehaviorSubject<boolean>,
  audioPlayer: HTMLAudioElement,
  currentScreenState$: BehaviorSubject<StateMachine.Steps>,
) => {
  return StateMachine.performAction(StateMachine.ScreenActions.Previous, skip$, audioPlayer, currentScreenState$)(_event, _props, element)
}

export const handleRestart = (
  _event: unused,
  _props: unused,
  element: HTMLButtonElement,
  skip$: BehaviorSubject<boolean>,
  audioPlayer: HTMLAudioElement,
  currentScreenState$: BehaviorSubject<StateMachine.Steps>,
) => {
  return StateMachine.performAction(StateMachine.ScreenActions.Restart, skip$, audioPlayer, currentScreenState$)(_event, _props, element)
}
