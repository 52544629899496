import { BehaviorSubject } from 'rxjs'
import { forTarget } from '@core/content'

export const enum AudioState {
  off,
  backgroundNoiseControl = 'control.backgroundNoise',
  backgroundNoiseProduct = 'product.backgroundNoise',
  quietTVControl = 'control.quietTV',
  quietTVProduct = 'product.quietTV',
  musicStreamingControl = 'control.musicStreaming',
  musicStreamingProduct = 'product.musicStreaming',
}

export const controlTracks: Record<string, string> = {
  backgroundNoise:
    'https://soundly-audio-files.s3.amazonaws.com/New%20Live%20Listen%20Files/Open%20Ear/Open%20Ear%20Background%20Noise%20Double%20Filter.wav',
  quietTV:
    'https://soundly-audio-files.s3.amazonaws.com/New%20Live%20Listen%20Files/Open%20Ear/Double%20Filter%20EQ%20Loss%20File.wav',
  musicStreaming: '',
}

export type ProductTracks = {
  backgroundNoise: string,
  quietTV: string,
  musicStreaming: string,
}

/**
 * deselectButtons
 *
 * This resets any and all buttons to a paused state by updating their aria-labels.
 * CSS in Webflow handles the corresponding styles.
 */
function deselectButtons () {
  Array.from(document.querySelectorAll('[aria-label="pause"][dk-action]')).forEach((button) => {
    button.setAttribute('aria-label', 'play')
  })
}

/**
 * disableMusicStreaming
 *
 * This disables the musicStreaming control button in the dropdown,
 * since there are no control tracks for musicStreaming
 */
export function disableMusicStreaming () {
  const musicStreamingControlButton = document.querySelector<HTMLElement>('[dk-value="control.musicStreaming"]')
  musicStreamingControlButton?.setAttribute('disabled', '')
}

/**
 * decorateButton
 *
 * Adds or removes pause attributed when a button is clicked.
 * If button should play, pauses all other buttons using deselectButtons()
 *
 * @param el
 */

export const audioPlayer = new Audio()
export const audioState$ = new BehaviorSubject<AudioState>(AudioState.off)

export function decorateButton (el: HTMLElement) {
  document.querySelectorAll('[dk-selected]').forEach((button) => {
    button.removeAttribute('dk-selected')
  })

  let clickedElement
  if (el.nodeName !== 'Button') {
    clickedElement = el.closest('button')!
  } else {
    clickedElement = el
  }

  clickedElement.setAttribute('dk-selected', '')

  if (clickedElement.getAttribute('aria-label') === 'pause') {
    deselectButtons()
    audioPlayer.pause()
  } else if (clickedElement.getAttribute('aria-label') === 'play') {
    deselectButtons()
    clickedElement.setAttribute('aria-label', 'pause')

    // also decorate any corresponding buttons
    const product = clickedElement.getAttribute('dk-slug')
    const track = clickedElement.getAttribute('dk-value')
    Array.from(document.querySelectorAll<HTMLButtonElement>(`[dk-slug='${product}'][dk-value='${track}']`)).forEach((matchingButton) => {
      matchingButton.setAttribute('aria-label', 'pause')
      matchingButton.setAttribute('dk-selected', '')
    })
  }
}

/**
 * buildAudioPlayer
 *
 * Sets up the audio player instance and appends it to the root element.
 */
export function buildAudioPlayer (root: RootElement) {
  audioPlayer.classList.add('sr-only', 'visually-hidden')
  audioPlayer.src = controlTracks.backgroundNoise
  audioPlayer.loop = true
  document.querySelectorAll('Audio').forEach((player) => player.remove())
  root.appendChild(audioPlayer)
}

/**
 * handleButtonPress
 *
 * Decides what audio state should be when a certain product button is pressed
 *
 * @param currentProduct
 */
export function handleButtonPress (currentProduct: string | null) {
  switch (currentProduct) {
    case AudioState.backgroundNoiseControl:
    case AudioState.backgroundNoiseProduct:
      audioState$.next(currentProduct)
      break

    case AudioState.quietTVControl:
    case AudioState.quietTVProduct:
      audioState$.next(currentProduct)
      break

    case AudioState.musicStreamingControl:
    case AudioState.musicStreamingProduct:
      audioState$.next(currentProduct)
      break

    default:
      break
  }
}

/**
 * displayDropdown
 *
 * This displays the audio comparison dropdown when applicable
 *
 * @param root
 * @returns
 */
export function displayDropdown (root: RootElement): void {
  const dropdown = forTarget<HTMLDivElement>(root, 'audio-comparison-dropdown')
  if (!dropdown) return

  if (isProductDetailWithZeroTracks(root)) return

  dropdown.style.display = 'block'
}

/**
 * isProductDetailWithZeroTracks
 *
 * This determines if the component exists on a product detail page where it shouldn't
 *
 * @param root
 * @returns
 */
function isProductDetailWithZeroTracks (root: RootElement): boolean {
  if ((root as HTMLElement).getAttribute('dk-target') !== 'product-detail') return false

  const dataElement = forTarget<HTMLDivElement>(root, 'product-data')
  if (!dataElement) {
    console.warn('Missing element with dk-target="product-data"')
    return false
  }

  const productAttributes = ['backgroundNoise', 'quietTV', 'musicStreaming']
  return !productAttributes.some(attr => getElementValue(dataElement, attr))

  // get the dk-value attribute value from an HTMLElement inside dataElement using dk-target attribute.
  function getElementValue (dataElement: HTMLDivElement, attributeName: string): string {
    return dataElement.querySelector<HTMLElement>(`[dk-target='product-${attributeName}']`)?.getAttribute('dk-value') ?? ''
  }
}
