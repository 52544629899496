export const SEARCH_URL = process.env.SEARCH_URL
export const FILTER_COLLECTION_KEY = 'dk-filter-collection' // name of the collection that the filtering will be done against (collection slug)
export const FILTER_TARGET_KEY = 'dk-filter-target' // Target of where the templated items will be placed on the DOM (#)
export const TEMPLATE_KEY = 'dk-filter-template' // Name of the template (match to whatever the template uses as value for dk-template attribute must be on same element that has dk-filter-collection)
export const ITEM_KEY = 'dk-filter-item-value' // Items used as filter triggers (filter item slug)
export const ITEM_SLUG_KEY = 'dk-filter-item-slug' // Slug of the attribute you are filtering on (filter items' collection slug)
export const ITEM_EVENT_KEY = 'dk-filter-item-event' // The event to trigger the filter, default is click
export const SINGLE_SELECT_ITEM_GROUP_KEY = 'dk-filter-single-select-group'
export const RESET_KEY = 'dk-filter-reset'
export const DEFAULT_SORT='dk-filter-default-sort'
export const DEFAULT_SORT_DIRECTION='dk-filter-default-sort-direction'
export const ITEM_SORT_KEY = 'dk-filter-sort'
export const ITEM_SORT_DIRECTION_KEY = 'dk-filter-sort-direction'
export const DK_TEMPLATE_KEY = 'dk-template'
export const DK_VALUE = 'dk-value'
export const DK_HIDDEN_VALUE = 'dk-hidden-value'
export const DK_SEARCH_FIELDS = 'dk-search-fields'
export const DK_SEARCH_INPUT = 'dk-search-input'
export const DK_SEARCH_FORM = 'dk-search-form'


