/**
 * FiltersQueryParams
 *
 * Uses query params to click appropriate filter on Shop page. If no matching
 * filter is specified here, no filter is clicked
 */
const FiltersQueryParams = () => {
  const params = new URLSearchParams(window.location.search)
  const filter = params.get('filter')
  let filterToClick

  switch (filter) {
    case ('bluetooth'):
      filterToClick = document.querySelector<HTMLInputElement>('[dk-filter-item-slug="bluetooth"]')!.click()
      break
    case ('in-the-ear'):
      filterToClick = document.querySelector<HTMLInputElement>('[dk-filter-item-slug="in-the-ear"]')!.click()
      break
    case ('affordable'):
      // this is the '$' price tier:
      filterToClick = document.querySelector<HTMLInputElement>('[dk-filter-item-value="e5ddfbd86d61616214ff88e9049bf5a4"]')!.click()
      break
    default:
      return
  }
}

export default FiltersQueryParams
