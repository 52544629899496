import type { ComponentActions } from '@core/bootstrap'
import { mount } from '@core/mount'
import { on } from '@core/on'
import { BehaviorSubject } from 'rxjs'

import * as AudioManagement from './audioManagement'
import * as EventHandlers from './eventHandlers'
import * as ResultsView from './results'
import * as ResultsChart from './resultsChart'
import * as ResultsTable from './resultsTable'
import * as StateMachine from './stateMachine'
import * as UIComponents from './uiComponents'

type HearingTestProps = {
  currentScreenState$: BehaviorSubject<StateMachine.Steps>
  skip$: BehaviorSubject<boolean>
}

const URL_BASE = `${process.env.API_URL}/audio/Soundly_SP` // SND AWS
const CALIBRATION_FILE = `${URL_BASE}_calibrated.mp3`

export default (root: RootElement, { currentScreenState$, skip$ }: HearingTestProps): ComponentActions => {
  const audioPlayer = document.createElement('audio')

  return {
    watch: [
      {
        target: currentScreenState$,
        actions: [
          () => UIComponents.hideAllCards(root),
          () => UIComponents.handleStep(root, currentScreenState$.getValue()),
        ],
      },
      {
        target: skip$,
        actions: [() => {}],
      },
    ],
    start: mount(
      root,
      () => {
        // if (window.__DK__) {
        //   UIComponents.bypassEverything(currentScreenState$)
        // }
        AudioManagement.buildAudioPlayer(audioPlayer)
        AudioManagement.watchCalibrationButton(root, audioPlayer, CALIBRATION_FILE)
        AudioManagement.watchRangeInputs(audioPlayer, URL_BASE)
        ResultsView.buildSplide(root)
        ResultsChart.buildResultsChart(root)
        ResultsTable.buildResultsTable(root)
        UIComponents.watchCheckboxes(root)
        UIComponents.watchQuestionnaireForCompletion(root, skip$)
      },
      on('display-chart', () => {
        ResultsView.displayChart(root)
      }),
      on('display-chart', () => {
        EventHandlers.handleNext(
          'click',
          {},
          document.querySelector<HTMLButtonElement>('[dk-action="display-chart:click"]')!,
          skip$,
          audioPlayer,
          currentScreenState$,
        )
      }),
      on('previous', () => {
        EventHandlers.handlePrevious(
          'click',
          {},
          document.querySelector<HTMLButtonElement>('[dk-action="previous:click"]')!,
          skip$,
          audioPlayer,
          currentScreenState$,
        )
      }),
      on('next', () => {
        EventHandlers.handleNext(
          'click',
          {},
          document.querySelector<HTMLButtonElement>('[dk-action="next:click"]')!,
          skip$,
          audioPlayer,
          currentScreenState$,
        )
      }),
      on('restart', () => {
        EventHandlers.handleRestart(
          'click',
          {},
          document.querySelector<HTMLButtonElement>('[dk-action="restart:click"]')!,
          skip$,
          audioPlayer,
          currentScreenState$,
        )
      }),
    ),
  }
}
