import { GraphQLRequest } from 'apollo-server-core/dist/requestPipeline'
import { Observable } from 'rxjs'
import { ajax, AjaxResponse } from 'rxjs/ajax'

const endpoint = process.env.GRAPHQL_URL!

type GQLResponseData = Record<string, { __typename: string }>

/**
 * Makes a request to a GraphQL endpoint
 *
 * @param params
 */
export function gqlQuery (params: Omit<GraphQLRequest, 'http'>): Observable<any> {
  const { query, variables = {} } = params
  return ajax.post(endpoint, { query, variables })
}

/**
 * call
 *
 * destructures an ajax response and sends its data to an action
 *
 * @param action
 * @return {function}
 */
export function call<T> (action: (data: T | any) => void) {
  return ({ response: { data } }: AjaxResponse<{ data: T | any }>) => action(data)
}

/** isErrorResponse
 *
 * @param data
 */
export function isErrorResponse (data: GQLResponseData) {
  return Object.keys(data).some((key) => data[key]?.__typename.match(/error/i))
}

/**
 * isSuccessResponse
 *
 * @param responseData
 */
export function isSuccessResponse (responseData: GQLResponseData) {
  return !isErrorResponse(responseData)
}
