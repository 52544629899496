import { forTarget } from '@core/content'
import { saveToSupabase } from './saveHearingTest'
import Chart, { BubbleDataPoint, ScatterDataPoint } from 'chart.js/auto'
import Moment from 'moment'
import type { Chart as ChartType } from 'chart.js/auto'

const frequencyTiers = [250, 500, 1000, 2000, 4000, 8000]
let resultsChart: ChartType

const buildFrequencyLabels = (tiers: number[]) => {
  return tiers.map((tier) => tier.toString())
}
const frequencyLabels = buildFrequencyLabels(frequencyTiers)

export const buildResultsChart = (root: RootElement) => {
  const canvas = document.querySelector<HTMLCanvasElement>('[id="hearing-test-results"]')!
  if (!canvas) return console.warn('Missing canvas with id="hearing-test-results"')

  let image: any

  const data = {
    labels: frequencyLabels,
    datasets: [
      {
        label: 'Left Ear',
        data: [],
        fill: false,
        borderColor: '#1F4E96',
        backgroundColor: '#1F4E96',
        borderWidth: 2,
        tension: 0.35,
      },
      {
        label: 'Right Ear',
        data: [],
        fill: false,
        borderColor: '#E77E2B',
        backgroundColor: '#E77E2B',
        borderWidth: 2,
        tension: 0.35,
      },
    ],
  }

  const options = {
    animation: {
      onComplete: async function (event: any) {
        image = resultsChart.toBase64Image()
        localStorage.setItem('imageBase64', image)

        const todaysDate = new Date().toLocaleString('en-US', {})
        const formattedDate = Moment(todaysDate).format('YYYY-MM-DD-HHMM')
        const printButton = forTarget<HTMLAnchorElement>(root, 'print-results')!

        if (!printButton) console.warn('Missing dk-target="print-results"')

        printButton.href = image
        printButton.download = `Soundly-hearing-test-results_${formattedDate}.png`

        if (!event.initial) {
          await saveToSupabase(saveHearingTestToHubspot)
        }
      },
    },
    maintainAspectRatio: false,
    elements: {
      point: {
        backgroundColor: 'transparent',
        radius: 5,
      },
    },
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 30,
          boxWidth: 6,
          color: '#202633',
          font: {
            size: 11,
          },
        },
      },
      tiers: {
        tier0: '#fcfcfc',
        tier1: 'rgba(32, 38, 51, 0.05)',
        tier2: 'rgba(32, 38, 51, 0.11)',
        tier3: 'rgba(32, 38, 51, 0.21)',
        tier4: 'rgba(32, 38, 51, 0.31)',
      },
    },

    scales: {
      y: {
        min: -10,
        max: 90,
        ticks: {
          autoSkip: false,
          includeBounds: true,
          padding: 16,
        },
        title: {
          text: 'Hearing Levels (dB)',
          display: true,
        },
        reverse: true,
      },
      x: {
        position: 'top',
        ticks: {
          padding: 12,
        },
        title: {
          text: 'Frequency (Hz)',
          display: true,
        },
      },
    },
  }

  const backgroundTiersPlugin = {
    id: 'tiers',
    beforeDraw: (chart: Chart, _args: any, options: any) => {
      const { ctx } = chart
      const { top, bottom, left, width, height } = chart.chartArea
      ctx.save()

      ctx.fillStyle = options.tier0
      ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height)

      ctx.fillStyle = options.tier1
      ctx.fillRect(left, top, width, height / 3.333)

      ctx.fillStyle = options.tier2
      ctx.fillRect(left, top + height / 3.333, width, height / 5)

      ctx.fillStyle = options.tier3
      ctx.fillRect(left, top + height / 2, width, height / 5)

      ctx.fillStyle = options.tier4
      ctx.fillRect(left, bottom - height / 3.333, width, height / 3.333)

      ctx.restore()
    },
  }

  const config: any = {
    type: 'line',
    data: data,
    options: options,
    plugins: [backgroundTiersPlugin],
  }

  resultsChart = new Chart(canvas, config)
}

export const updateResultsChart = (
  dataLeft: (number | ScatterDataPoint | BubbleDataPoint | null)[],
  dataRight: (number | ScatterDataPoint | BubbleDataPoint | null)[],
) => {
  resultsChart.data.datasets[0].data = dataLeft
  resultsChart.data.datasets[1].data = dataRight
  resultsChart.update()
}
async function saveHearingTestToHubspot(email: string, image: string) {
  const query = `#graphql
    mutation saveHearingTest ($payload: HearingTestPayload) {
    saveHearingTest (payload: $payload ) {
      success
    }
  }
  `

  await fetch(process.env.GRAPHQL_URL!, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query,
      variables: {
        payload: {
          email,
          image,
        },
      },
    }),
  })
}
