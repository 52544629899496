import {
  ProductDataFromLink,
  ProductsObservable,
  setProductComparisonData,
  toggleProductSelection,
} from '@lib/comparisonTrigger'

import { announceThis } from '@lib/a11yAnnouncer'
import { clearContents, fromTemplate } from '@core/template'
import { forTarget } from '@core/content'
import { fromEvent } from 'rxjs'
import { mount } from '@core/mount'
import { on } from '@core/on'

type ProductComparisonProps = {
  products$: ProductsObservable
}

/**
 * ProductComparison
 *
 * @param root
 * @param products$
 */
export default function ProductionComparison (root: RootElement, { products$ }: ProductComparisonProps) {

  /**
   * addPillsToComparisonList
   *
   * @param products
   */
  function addPillsToComparisonList (products: Record<any, ProductDataFromLink>) {
    const container = forTarget<HTMLDivElement>(root, 'items-to-compare')!
    clearContents(container)

    for (const product of Object.values(products)) {
      const template = fromTemplate('compare-item', product)!
      const removeButton = forTarget<HTMLElement>(template, 'remove-item')!
      removeButton.setAttribute('aria-label', `Remove ${product.name} from comparison`)
      container.appendChild(template)
      handleRemoveLink(template, product)
    }
  }

  /**
   * handleRemoveLink
   *
   * @param pill
   * @param product
   */
  function handleRemoveLink (pill: HTMLElement, product: ProductDataFromLink) {
    fromEvent(pill, 'click').subscribe(() => {
      const currentValue = products$.getValue() as Record<string, ProductDataFromLink>
      delete currentValue[product.slug]
      products$.next({ ...currentValue })
    })
  }

  /**
   * handleInitialProductComparisonLinks
   *
   * Wrapper around setProductComparisonData so that it can be used with the on function.
   *
   * @param event
   */
  function handleInitialProductComparisonLinks (event: CustomEvent) {
    setProductComparisonData(event, products$)
  }

  /**
   * setInitialStateForElements
   *
   * on page load, if no filters are applied, appropriately toggle the selections and watch products for change
   */
  function setInitialStateForElements () {
    root.querySelectorAll('[role="listitem"]').forEach((element) => {
      toggleProductSelection(element as HTMLElement, products$)
    })
  }

  /**
   * announceProductComparison
   *
   * Updates live region message when products added or removed from comparison
   *
   * @param products
   */
  function announceProductComparison (products: Record<any, ProductDataFromLink>) {
    let message = ''
    const productsArray: string[] = []

    if (Object.keys(products).length === 0) {
      message = ''
    } else {
      for (const product of Object.values(products)) {
        productsArray.push(product.name)
      }
      message = `Products in comparison include: ${productsArray.join(', ')}`
    }

    if (message !== '') {
      announceThis(message, 'polite', 'announce-comparison')
    }
  }

  /**
   * buildComparisonLiveRegion
   *
   * Create and add placeholder element into which comparison announcements will go
   */
  function buildComparisonLiveRegion () {
    const messageElement = document.createElement('div')
    messageElement.id = 'announce-comparison'
    messageElement.setAttribute('aria-live', 'polite')
    document.body.append(messageElement)
  }

  /**
   * hideCompareButtonIfEmpty
   *
   * Hides the compare button if there are no products in the list.
   *
   * This function checks the length of the `products` object retrieved from
   * the `products$` stream. If the length is 0, it means there are no products,
   * and the compare button's display property will be set to 'none', effectively
   * hiding it. Otherwise, the display property is set to 'inline-block'.
   */
  function hideCompareButtonIfEmpty () {
    const products = products$.getValue()
    const compareButton = document.querySelector<HTMLElement>('.shop-compare__button-wrap .button')

    if (compareButton) {
      compareButton.style.display = Object.keys(products).length === 0 ? 'none' : 'inline-block'
    }
  }

  return {
    watch: [{
      target: products$,
      actions: [
        addPillsToComparisonList,
        announceProductComparison,
        hideCompareButtonIfEmpty,
      ],
    }],
    start: mount(root,
      on('compare-product', handleInitialProductComparisonLinks),
      setInitialStateForElements,
      buildComparisonLiveRegion,
    ),
  }
}
