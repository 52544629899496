import { mount } from '@core/mount'
import { forTarget } from '@core/content'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  const handleButtons = () => {
    // visible, decoy add-to-cart buttons
    const buttonDecoyHome: Maybe<HTMLLinkElement> = forTarget(root, 'add-to-cart-home')
    if (!buttonDecoyHome) return console.warn('missing element with dk-target="add-to-cart-home"')
    const buttonDecoyClinic: Maybe<HTMLLinkElement> = forTarget(root, 'add-to-cart-clinic')
    if (!buttonDecoyClinic) return console.warn('missing element with dk-target="add-to-cart-clinic"')

    // hidden, selector buttons
    const webflowSelectors = Array.from(root.querySelectorAll<HTMLElement>('[dk-target="webflow-selector"]'))
    const webflowSelectorHome = webflowSelectors[0]
    const webflowSelectorClinic = webflowSelectors[1]

    // hidden, "real" add-to-cart buttons
    const buttonWebflowHome: Maybe<HTMLLinkElement> = forTarget(root, 'webflow-add-to-cart-home')
    if (!buttonWebflowHome) return console.warn('missing element with dk-target="webflow-add-to-cart-home"')
    const buttonWebflowClinic: Maybe<HTMLLinkElement> = forTarget(root, 'webflow-add-to-cart-clinic')
    if (!buttonWebflowClinic) return console.warn('missing element with dk-target="webflow-add-to-cart-clinic"')

    buttonDecoyHome!.addEventListener('click', () => {
      if (webflowSelectorHome) {
        webflowSelectorHome.click()
      }
      setTimeout(() => {
        buttonWebflowHome!.click()
      }, 300)
    })
    buttonDecoyClinic!.addEventListener('click', () => {
      if (webflowSelectorClinic) {
        webflowSelectorClinic.click()
      }
      setTimeout(() => {
        buttonWebflowClinic!.click()
      }, 300)
    })
  }

  return {
    start: mount(root, handleButtons),
  }
}
