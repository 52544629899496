import { mount } from '@core/mount'
import { Splide } from '@splidejs/splide'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  const buildSplide = () => {
    setTimeout(() => {
      const list = root.querySelector('.splide__list')
      if (list && list.childNodes.length > 1) {
        new Splide(root as HTMLElement, {
          pagination: false,
          perPage: 1,
          mediaQuery: 'min',
          breakpoints: {
            768: {
              perPage: 2,
            },
            992: {
              perPage: 3,
            },
          },
        }).mount()
      }
    }, 1000)
  }

  return {
    start: mount(root, buildSplide),
  }
}
