import camelCase from 'lodash.camelcase'

/**
 * camelCaseKeys
 *
 * recursively converts keys of an object to camelCase
 *
 * @param record
 */
export default function camelCaseKeys<T> (record: Record<string, any>): T {
  return Object.keys(record).reduce((acc: Record<string, any>, key: string) => {
    let value = record[key]

    if (Array.isArray(value)) {
      value = value.map((v: any) => {
        return typeof v === 'object' ? camelCaseKeys(v) : v
      })
    }

    acc[camelCase(key)] = typeof value === 'object' && !Array.isArray(value) ? camelCaseKeys(value) : value
    return acc
  }, {}) as T
}
