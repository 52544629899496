import { BehaviorSubject } from 'rxjs'
import { ComponentActions } from '@core/bootstrap'
import { mount } from '@core/mount'
import { on } from '@core/on'
import NavDropdownsCustom from '@modules/navDropdownsCustom'

import type { ProductTracks } from './AudioComparisonShared'
import {
  AudioState,
  audioPlayer,
  audioState$,
  buildAudioPlayer,
  controlTracks,
  decorateButton,
  disableMusicStreaming,
  displayDropdown,
  handleButtonPress,
} from './AudioComparisonShared'

type AudioComparisonProps = {
  products$: BehaviorSubject<Record<string, Product>>
}

const productTracks: Record<string, ProductTracks> = {}

export default function AudioComparison (root: RootElement, props: AudioComparisonProps): ComponentActions {
  const { products$ } = props
  let currentProductSlug: string
  let currentProductName: string
  let currentTrackName = 'backgroundNoise'
  let matchedTime = 0

  const currentProduct$ = new BehaviorSubject<string | null>(null)

  /**
   * buildListOfProductTracks
   *
   * Filters out and maps product key-value pairs to add them into the
   * productTracks object as key: string and value: ProductTracks.
   *
   * @param products
   */
  function buildListOfProductTracks (products: Record<string, Product> = {}) {
    for (const [key, product] of Object.entries(products)) {
      const trackObj: ProductTracks = {
        backgroundNoise: '',
        quietTV: '',
        musicStreaming: '',
      }

      if (product.audioFileBackgroundNoise) {
        trackObj.backgroundNoise = product.audioFileBackgroundNoise
      }

      if (product.audioFileQuietTV) {
        trackObj.quietTV = product.audioFileQuietTV
      }

      if (product.audioFileMusicStreaming) {
        trackObj.musicStreaming = product.audioFileMusicStreaming
      }

      if (trackObj.backgroundNoise || trackObj.quietTV || trackObj.musicStreaming) {
        productTracks[key] = trackObj
      }
    }
  }

  /**
   * setDefaultProduct
   *
   * Sets the first item in productTracks as the default product.
   */
  function setDefaultProduct () {
    // default SLUG tasks
    currentProductSlug = Object.keys(productTracks)[0]
    currentProduct$.next(currentProductSlug)

    // disable all productButtons, then enable the ones that have tracks, ie those in producttracks
    root.querySelectorAll<HTMLButtonElement>('[dk-action="productButton:click"]').forEach((button) => {
      button.setAttribute('disabled', '')
    })
    // enable any productButtons with slugs corresponding to keys in productTracks
    Object.keys(productTracks).forEach((productSlug) => {
      root.querySelectorAll<HTMLButtonElement>(`[dk-slug="${productSlug}"][dk-action="productButton:click"]`).forEach((button) => {
        button.removeAttribute('disabled')
      })
    })
  }

  /**
   * handleMissingTracks
   *
   * When the currentProduct changes, this handles enabling and disabling the
   * appropriate buttons in the dropdown
   *
   * @param currentProduct
   */
  function handleMissingTracks (currentProduct: string | null) {
    if (!currentProduct) return
    if (currentProduct.includes('control.')) return

    const product = productTracks[currentProduct]
    if (!product) return

    // enable all dropdown buttons...
    const dropdownButtons = root.querySelectorAll<HTMLButtonElement>('[dk-action="dropdownButton:click"]')
    dropdownButtons.forEach((button) => button.removeAttribute('disabled'))

    // ...except musicStreaming control button
    disableMusicStreaming()

    // disable dropdown product buttons for each track that doesn't exist for current product
    if (!product.backgroundNoise) {
      root.querySelector<HTMLButtonElement>('[dk-action="dropdownButton:click"][dk-value="product.backgroundNoise"]')?.setAttribute('disabled', '')
    }
    if (!product.quietTV) {
      root.querySelector<HTMLButtonElement>('[dk-action="dropdownButton:click"][dk-value="product.quietTV"]')?.setAttribute('disabled', '')
    }
    if (!product.musicStreaming) {
      root.querySelector<HTMLButtonElement>('[dk-action="dropdownButton:click"][dk-value="product.musicStreaming"]')?.setAttribute('disabled', '')
    }
  }

  /**
   * updateCurrentProduct
   *
   * Updates currentProductSlug and currentProductName when a product play/pause
   * button is clicked
   *
   * @param el
   */
  function updateCurrentProduct (el: HTMLElement) {
    let clickedElement
    if (el.nodeName !== 'BUTTON') {
      clickedElement = el.closest('button')!
    } else {
      clickedElement = el
    }

    // product SLUG tasks
    currentProductSlug = clickedElement.getAttribute('dk-slug')!
    currentProduct$.next(currentProductSlug)

    const currentProductAudioState = clickedElement.getAttribute('dk-value')!
    audioState$.next(currentProductAudioState as AudioState)
  }

  /**
   * updateProductButtons
   *
   * Whenever the AudioState updates, make sure product buttons are updated
   * to reflect whether or not they have a file for the current track type
   */
  function updateProductButtons () {
    // enable all product buttons
    const productButtons = root.querySelectorAll<HTMLButtonElement>('[dk-action="productButton:click"]')
    productButtons.forEach((button) => {
      button.removeAttribute('disabled')

      const thisProductSlug = button.getAttribute('dk-slug')!
      if (!productTracks[thisProductSlug]) {
        button.setAttribute('disabled', '')
        return
      }

      // @ts-ignore
      if (productTracks[thisProductSlug][currentTrackName] === '') {
        button.setAttribute('disabled', '')
      }
    })
  }

  /**
   * updatePlayer
   *
   * Sets the audio source based on the button click, sets the current time
   * of the audio player, and finally plays the audio.
   *
   * @param track
   * @returns
   */
  function updatePlayer (track: AudioState) {
    if (track === AudioState.off) return

    const [audioType, trackName] = track.split('.')

    // if we're trying to play a new track with the same name,
    // record the point at which we stopped the original track
    if (currentTrackName === trackName) {
      matchedTime = audioPlayer.currentTime
    } else {
      matchedTime = 0
    }

    // set audioPlayer's src
    if (audioType === 'control') {
      audioPlayer.src = controlTracks[trackName]
    } else if (audioType === 'product') {
      // @ts-ignore
      audioPlayer.src = productTracks[currentProductSlug][trackName]
    }

    // update currentTrackName, so we can check it on the next button press
    currentTrackName = trackName

    // update productButtons' dk-value to use currentTrackName
    Array.from(root.querySelectorAll<HTMLButtonElement>('[dk-action="productButton:click"]')).forEach((productButton) => {
      productButton.setAttribute('dk-value', `product.${trackName}`)
    })

    // update dropdown productButtons dk-slug to use currentProductSlug
    Array.from(root.querySelectorAll<HTMLButtonElement>('[dk-action="dropdownButton:click"][dk-value^="product"]')).forEach((dropdownProductButton) => {
      dropdownProductButton.setAttribute('dk-slug', `${currentProductSlug}`)
      dropdownProductButton.setAttribute('dk-name', `${currentProductName}`)
    })

    // set audioPlayer's currentTime
    audioPlayer.currentTime = matchedTime

    audioPlayer.play()
  }

  return {
    watch: [{
      target: products$,
      actions: [
        buildListOfProductTracks,
        setDefaultProduct,
        disableMusicStreaming,
      ],
    },
    {
      target: currentProduct$,
      actions: [
        handleMissingTracks,
        handleButtonPress,
      ],
    },
    {
      target: audioState$,
      actions: [
        updatePlayer,
        updateProductButtons,
      ],
    },
    ],
    start:
      mount(
        root,
        displayDropdown,
        buildAudioPlayer,
        NavDropdownsCustom,
        on('dropdownButton', (el) => {
          currentProduct$.next(el.target.getAttribute('dk-value')!)
          decorateButton(el.target)
        }),
        on('productButton', (el) => {
          updateCurrentProduct(el.target)
          decorateButton(el.target)
        }),
      ),
  }
}
