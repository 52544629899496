// noinspection JSDeprecatedSymbols

import { BehaviorSubject } from 'rxjs'
import { ComponentActions } from '@core/bootstrap'
import { ajax } from 'rxjs/ajax'
import { assertNonNullish } from '@lib/assert'
import { forTarget } from '@core/content'
import { getInputValues } from '@core/forms'
import { mount } from '@core/mount'
import { on } from '@core/on'
import { gqlQuery } from '@core/gqlQuery'
import { retry } from 'rxjs/operators'

enum FormStep {
  LOAD,
  LOADED,
  HONEYPOT,
  SUBMIT,
  ERROR,
}

type LeadFormData = {
  destination: string
  firstName: string
  lastName: string
  email: string
}

export function LeadGenForm(root: HTMLDivElement, _props: any): ComponentActions {
  const step$ = new BehaviorSubject<FormStep>(FormStep.LOAD)
  // Set up for reCAPTCHA
  const form = forTarget<HTMLFormElement>(root, 'leadgen-form')!
  let iv: string // To complete the request you need two parts of the Hash. One is stored in memory, the other an input.

  const CreateLeadQuery = `#graphql
  mutation CreateLead ($payload: CreateLeadPayload) {
    createLead (payload: $payload) {
      success
    }
  }
  `

  /**
   * handleLoad
   *
   * fetches a hash/token from the API and sets it as a hidden text field inside the form.
   * This was partially inspired by
   * https://www.smartfile.com/blog/captchas-dont-work-how-to-trick-spam-bots-with-a-smarter-honey-pot/
   */
  function handleLoad() {
    ajax.get(`${process.env.API_URL}honeypot`).subscribe(({ response }: any) => {
      iv = response.iv

      const input = document.createElement('input') as HTMLInputElement
      input.style.display = 'none'
      input.type = 'text'
      input.name = 'challenge'
      input.setAttribute('value', response.content)
      input.setAttribute('dk-input', 'content')
      form.prepend(input)

      step$.next(FormStep.LOADED)
    })
  }

  /**
   * handleHoneypot
   *
   * verifies the hash provided when the form was loaded
   */
  function handleHoneypot() {
    // const { content } = getInputValues(form)

    // ajax.post(`${process.env.API_URL}honeypot`, { iv, content })
    //   .pipe(retry(3))
    //   .subscribe(
    //     () => step$.next(FormStep.SUBMIT),
    //     () => step$.next(FormStep.ERROR),
    //   )
    // console.log(iv)

    step$.next(FormStep.SUBMIT)
  }

  /**
   * handleSubmit
   *
   * submits a lead to hubspot and saves the email address to the user's local storage
   */
  function handleSubmit() {
    const { email, firstName, lastName } = getInputValues(form)
    const destination = 'Hearing Test'

    const variables: { payload: LeadFormData } = {
      payload: { destination, email, firstName, lastName },
    }

    gqlQuery({ query: CreateLeadQuery, variables })
      .pipe(retry(3))
      .subscribe(
        () => console.info('Form submitted'),
        () => step$.next(FormStep.ERROR),
      )

    // Save the email address to local storage
    localStorage.setItem('email', email)

    closeModal()
  }

  /**
   * closeModal
   *
   * closes the contact form
   */
  function closeModal() {
    const hiddenCloseButton = forTarget<HTMLButtonElement>(root, 'hidden-close-button')!
    assertNonNullish(hiddenCloseButton, 'Missing dk-target="hidden-close-button"')

    hiddenCloseButton.click()
  }

  function handleError() {
    console.error('There was an error submitting the form')
  }

  async function handleStepSubscription(step: FormStep) {
    switch (step) {
      case FormStep.LOAD:
        await handleLoad()
        break
      case FormStep.LOADED:
        break
      case FormStep.HONEYPOT:
        handleHoneypot()
        break
      case FormStep.SUBMIT:
        await handleSubmit()
        break
      default:
        handleError()
    }
  }

  /**
   * doAllTheThings
   *
   * kicks off the validation and submission process
   */
  async function doAllTheThings(event: Event, _action: unused, _target: unused) {
    event.preventDefault()
    step$.next(FormStep.HONEYPOT)
  }

  return {
    watch: [{ target: step$, actions: [handleStepSubscription] }],
    start: mount(root, on('hearing-test-gate-form', doAllTheThings)),
  }
}
