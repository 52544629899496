declare global {
  interface Window {
    __DK__: boolean;
  }
}

export function startDKApp (
  app: GenericFunction<void>,
  callback?: GenericFunction<any>,
): void {
  const isStaging = window.location.host.match(/webflow.io/);

  if (isStaging && !window.__DK__) {
    const LOCAL_JS_URL = process.env.LOCAL_JS_URL;
    const script = document.createElement('script');
    script.src = `${LOCAL_JS_URL}`;
    script.id = 'dk';

    document.body.appendChild(script);
    window.__DK__ = true;

    script.onload = () => {
      console.info('%c 🐵 DK Started', 'font-weight: bold');
    };

    script.onerror = app;

    if (callback) callback();
  } else {
    app();
  }
}
