import { FILTER_COLLECTION_KEY, ITEM_KEY, RESET_KEY, ITEM_SORT_KEY, TEMPLATE_KEY } from './FilterConstants'
import { FilterItem } from './FilterItem'

export class FilterCollection {
  private filterCollection: HTMLElement | null

  constructor (filterCollection: HTMLElement | null) {
    this.filterCollection = filterCollection
  }

  get element (): HTMLElement | null {
    return this.filterCollection
  }

  get key () {
    return this.filterCollection?.getAttribute(FILTER_COLLECTION_KEY) || ''
  }

  get items (): FilterItem[] {
    const items = this.filterCollection?.querySelectorAll(`[${ITEM_KEY}]`)
    if (items) {
      return Array.from(items).map((item) => new FilterItem(item as HTMLElement))
    } else {
      return []
    }
  }

  get resetFilterItems (): FilterItem[] {
    const items = this.filterCollection?.querySelectorAll(`[${RESET_KEY}]`)
    if (items) {
      return Array.from(items).map((item) => new FilterItem(item as HTMLElement))
    } else {
      return []
    }
  }

  get sortFilterItems (): FilterItem[] {
    const items = this.filterCollection?.querySelectorAll(`[${ITEM_SORT_KEY}]`)
    if (items) {
      return Array.from(items).map((item) => new FilterItem(item as HTMLElement))
    } else {
      return []
    }
  }

  get templateKey () {
    return this.filterCollection?.getAttribute(TEMPLATE_KEY)
  }
}
