import { ITEM_SLUG_KEY, ITEM_KEY, ITEM_SORT_DIRECTION_KEY } from './FilterConstants'

export class FilterItem {
  private filterItem: HTMLElement | null

  constructor (filterItem: HTMLElement | null) {
    this.filterItem = filterItem
  }

  public addSelectListener (callback: (event: Event) => void) {
    if (!this.filterItem) return

    this.filterItem.addEventListener(
      this.selectionEvent(this.filterItem),
      callback,
    )
  }

  resetItemSelection () {
    if (!this.filterItem) return
    if (this.filterItem.nodeName !== 'INPUT') return

    const item = this.filterItem as HTMLInputElement
    item.checked = false
  }

  get element () {
    return this.filterItem
  }

  get slug () {
    return this.slugItem()?.getAttribute(ITEM_SLUG_KEY)
  }

  get value () {
    return this.filterItem?.getAttribute(ITEM_KEY)
  }

  get sortDirection () {
    return this.filterItem?.getAttribute(ITEM_SORT_DIRECTION_KEY)
  }

  private selectionEvent (item: HTMLElement) {
    if (item.nodeName === 'INPUT') {
      return 'input'
    }
    return 'click'
  }

  private slugItem () {
    if (this.filterItem?.hasAttribute(ITEM_SLUG_KEY)) {
      return this.filterItem
    } else if (this.filterItem?.closest(`[${ITEM_SLUG_KEY}]`)) {
      return this.filterItem?.closest(`[${ITEM_SLUG_KEY}]`)
    }
  }
}
