type AnnouncementMethod = 'polite' | 'assertive' | 'off'

/**
 * announceThis
 *
 * Dynamically generates a live region to announce changes to screen reader users
 * Thanks to Patrick Fox, via https://jsfiddle.net/pfox/mq3x71kg/
 *
 * @param message
 * @param method
 * @param elementId
 */
export function announceThis (message: string, method?: AnnouncementMethod, elementId?: string) {
  let messageElement: HTMLElement | null
  if (elementId) {
    messageElement = document.getElementById(elementId)
  } else {
    messageElement = document.getElementById('announce-this')
  }
  if (!messageElement) return console.warn('Missing live region')
  method = method || 'polite'
  messageElement.setAttribute('aria-live', method)
  messageElement.innerHTML = message

  function clearMessage () {
    if (!messageElement) return
    messageElement.innerHTML = ''
  }
  setTimeout(clearMessage, 1000)
}
