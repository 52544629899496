import { forTarget, hideElement, showElement } from '@core/content'

export const buildAudioPlayer = (audioPlayer: HTMLAudioElement) => {
  audioPlayer.style.display = 'none'
  document.body.appendChild(audioPlayer)
}

export const switchTrack = async (
  range: HTMLInputElement,
  audioPlayer: HTMLAudioElement,
  URL_BASE: string,
) => {
  audioPlayer.loop = false // enforce non-looping by default
  audioPlayer.pause()
  audioPlayer.src = `${URL_BASE}_${range.id}_${range.value}.mp3`
  await audioPlayer.play()
}

export const watchCalibrationButton = async (root: RootElement, audioPlayer: HTMLAudioElement, CALIBRATION_FILE: string) => {
  let shouldPlay = true
  const calibrationButton = forTarget<HTMLButtonElement>(root, 'calibration-button')!
  const calibrationIconPlay = forTarget<HTMLImageElement>(root, 'calibration-button-play')!
  const calibrationIconPause = forTarget<HTMLImageElement>(root, 'calibration-button-pause')!
  if (!calibrationButton) return console.warn('Missing calibration button')
  if (!calibrationIconPlay) return console.warn('Missing play icon')
  if (!calibrationIconPause) return console.warn('Missing pause icon')

  calibrationButton.setAttribute('aria-label', 'Play calibration track')
  hideElement(calibrationIconPause)

  calibrationButton.addEventListener('click', function () {
    if (shouldPlay) {
      audioPlayer.pause()
      audioPlayer.src = CALIBRATION_FILE
      audioPlayer.loop = true
      audioPlayer.play()
      hideElement(calibrationIconPlay)
      showElement(calibrationIconPause)
      calibrationButton.classList.add('playing')
      calibrationButton.setAttribute('aria-label', 'Pause calibration track')
      shouldPlay = false
    } else {
      audioPlayer.pause()
      audioPlayer.loop = false
      hideElement(calibrationIconPause)
      showElement(calibrationIconPlay)
      calibrationButton.classList.remove('playing')
      calibrationButton.setAttribute('aria-label', 'Play calibration track')
      shouldPlay = true
    }
  })
}

export const buildVolumeButtons = async (
  range: HTMLInputElement,
  audioPlayer: HTMLAudioElement,
  URL_BASE: string,
) => {
  const decreaseButton = document.createElement('button')
  decreaseButton.innerHTML = '-'
  decreaseButton.setAttribute('aria-label', 'Decrease volume')
  range.insertAdjacentElement('beforebegin', decreaseButton)

  const increaseButton = document.createElement('button')
  increaseButton.innerHTML = '+'
  increaseButton.setAttribute('aria-label', 'Increase volume')
  range.insertAdjacentElement('afterend', increaseButton)

  decreaseButton.addEventListener('click', function (event) {
    event.preventDefault()
    const newValue = parseInt(range.value) - 5
    range.value = newValue.toString()
    if (range.name.includes('R')) {
      const correspondingRange = document.querySelector<HTMLInputElement>(`#hearing-test-form input[name="${range.name}"]`)!
      correspondingRange.value = range.value
    }
    switchTrack(range, audioPlayer, URL_BASE)
  })

  increaseButton.addEventListener('click', function (event) {
    event.preventDefault()
    const newValue = parseInt(range.value) + 5
    range.value = newValue.toString()
    if (range.name.includes('R')) {
      const correspondingRange = document.querySelector<HTMLInputElement>(`#hearing-test-form input[name="${range.name}"]`)!
      correspondingRange.value = range.value
    }
    switchTrack(range, audioPlayer, URL_BASE)
  })
}

export const watchRangeInputs = async (
  audioPlayer: HTMLAudioElement,
  URL_BASE: string,
) => {
  const hearingTestForm = document.getElementById('hearing-test-form')
  const hearingTestFormPart2 = document.getElementById('hearing-test-form-part2')
  if (!hearingTestForm || !hearingTestFormPart2) return

  Array.from(hearingTestForm.querySelectorAll<HTMLInputElement>('input[type="range"]')).forEach((range) => {
    buildVolumeButtons(range, audioPlayer, URL_BASE)

    range.addEventListener('input', function () {
      switchTrack(range, audioPlayer, URL_BASE)
    })
  })

  Array.from(hearingTestFormPart2.querySelectorAll<HTMLInputElement>('input[type="range"]')).forEach((range) => {
    buildVolumeButtons(range, audioPlayer, URL_BASE)

    range.addEventListener('input', function () {
      const correspondingRange = hearingTestForm.querySelector<HTMLInputElement>(`input[name="${range.name}"]`)!
      correspondingRange.value = range.value
      switchTrack(range, audioPlayer, URL_BASE)
    })
  })
}
