import SubmitEvent = JQuery.SubmitEvent
import { BehaviorSubject, fromEvent } from 'rxjs'
import { ComponentActions } from '@core/bootstrap'
import { clearContents, fromTemplate } from '@core/template'
import { forTarget } from '@core/content'
import { gqlQuery } from '@core/gqlQuery'
import { initGoogleSearch } from '@lib/googleMaps'
import { mount } from '@core/mount'
import { on } from '@core/on'
import { assertNonEmpty } from '@lib/assert'
import { setRatingStars } from '@modules/ratingStars'

type AudiologistSearch = {
  coordinates$: BehaviorSubject<Maybe<Coordinates>>
  searchLabel$: BehaviorSubject<Maybe<string>>
}

const audiologistQuery = `#graphql
  query AudiologistsNearby ($payload: NearbySearchPayload!) {
    audiologists: audiologistsNearby(payload: $payload) {
      id
      name
      about
      distance
      latitude
      longitude
      address
      city
      state
      zip
      rating
      reviews
    }
  }
`

export default function AudiologistSearch (root: RootElement, { coordinates$, searchLabel$ }: AudiologistSearch): ComponentActions {
  const audiologists$ = new BehaviorSubject<NearbyQueryResult[]>([])
  let map: google.maps.Map

  /**
   * displayMap
   *
   * Displays Google Maps using Wichita Kansas as the focal point and zoomed out to display the entire US.
   */
  function displayMap (): void {
    const container = forTarget<HTMLDivElement>(root, 'map')
    if (!container) return

    // eslint-disable-next-line no-new
    map = new google.maps.Map(container, {
      center: { lat: 34.0206842, lng: -118.5518137 },
      zoom: 6.5,
      disableDefaultUI: true,
      zoomControl: true,
    })

    map.setOptions({ minZoom: 4.5 })
  }

  /**
   * Fetches location based on the Google Places search results and updates the coordinates observable with the results
   *
   * @param service
   */
  async function getLocationFromPlacesSearch (service: any): Promise<void> {
    const place = service.getPlaces()[0]
    if (!place?.geometry) return

    const latitude = place.geometry.location.lat()
    const longitude = place.geometry.location.lng()

    const value = forTarget<HTMLInputElement>(root, 'location-search')!.value

    searchLabel$.next(value)

    coordinates$.next({
      latitude,
      longitude,
    })
  }

  /**
   * initializePlacesSearch
   *
   * Initializes Google Places on the search input field
   */
  function initializePlacesSearch (): void {
    const input = forTarget<HTMLInputElement>(root, 'location-search')!

    initGoogleSearch(input, async (service) => {
      await getLocationFromPlacesSearch(service)
    })
  }

  /**
   * setInputText
   *
   * sets the input value for the search box from the value of the last result
   */
  function setInputText (): void {
    forTarget<HTMLInputElement>(root, 'location-search')!.value = searchLabel$.getValue() || ''
  }

  /**
   * disableOrEnableSubmitButton
   *
   * if there are no coordinates set, the submit button is disable. It's re-enabled once the user searches for a
   * location.
   *
   * @param coords
   */
  function disableOrEnableSubmitButton (coords: Coordinates): void {
    const target = forTarget<HTMLButtonElement>(root, 'submit-button')!

    if (coords?.latitude) {
      target.removeAttribute('disabled')
      target.classList.remove('disabled')
    } else {
      target.setAttribute('disabled', 'disabled')
      target.classList.add('disabled')
    }
  }

  /**
   * fetchAudiologists
   *
   * fetches the audiologists for a given endpoint
   *
   * @param coordinates
   */
  function fetchAudiologists (coordinates: Coordinates): void {
    if (!coordinates) return

    const payload = {
      lat: coordinates.latitude,
      long: coordinates.longitude,
      radius: 50,
      limit: 90,
      offset: 0,
    }

    gqlQuery({
      query: audiologistQuery,
      variables: { payload },
    }).subscribe(({ response }) => audiologists$.next(response.data.audiologists))
  }

  /**
   * populateAudiologistResults
   *
   * creates an HTML link element for each audiologist used and replaces the contents of the results container with the
   * results. If no results are found the container is emptied
   *
   * @param records
   */
  function populateAudiologistResults (records: NearbyQueryResult[]): void {
    const container = forTarget<HTMLDivElement>(root, 'results-container')!
    if (!container) return

    clearContents(container)

    for (const record of records) {
      const elem = fromTemplate('audiologist-search-result', record)! as HTMLLinkElement

      const starsGroup = forTarget<HTMLDivElement>(elem, 'rating-stars')!
      starsGroup.setAttribute('dk-value', String(record.rating))
      setRatingStars(starsGroup)

      elem.href = `${elem.href}?id=${record.id}`
      container.append(elem)
    }
  }

  /**
   * handleFormSubmission
   *
   * Depending on the action set on the form element, when the user submits the form preforms the following action:
   * - update-results - updates the search results on the page
   * - default case redirects the user to the results page
   *
   * @param event
   */
  function handleFormSubmission (event: SubmitEvent): void {
    event.preventDefault()
    const target = event.target

    switch (target.dataset.action) {
      case 'update-results':
        fetchAudiologists(coordinates$.getValue()!)
        break
      default:
        if (coordinates$.getValue()) {
          window.location.pathname = '/audiologists/results'
        }
    }
  }

  /**
   * updateMap
   *
   * Adds the pins for a list of audiologists and zooms in on the one that best matches the search criteria, either by
   * distance or another filter
   *
   * @param audiologists
   */
  function updateMap (audiologists: NearbyQueryResult[]): void {
    try {
      assertNonEmpty<NearbyQueryResult>(audiologists, 'empty array returned')

      const { latitude: lat, longitude: lng } = audiologists[0]
      for (const audiologist of audiologists) {
        const { latitude: lat, longitude: lng } = audiologist
        // eslint-disable-next-line no-new
        new google.maps.Marker({ position: { lat, lng }, map, title: audiologist.name })
      }

      map.setCenter({ lat, lng })
      map.setZoom(11)
    } catch (error) {
      console.warn((error as any).message)
    }
  }

  function clearCoordinates (): void {
    fromEvent(forTarget<HTMLInputElement>(root, 'location-search')!, 'keyup').subscribe(() => {
      coordinates$.next(null)
    })
  }

  return {
    watch: [
      { target: coordinates$, actions: [disableOrEnableSubmitButton, fetchAudiologists] },
      { target: audiologists$, actions: [populateAudiologistResults, updateMap] },
    ],
    start: mount(root,
      initializePlacesSearch,
      setInputText,
      displayMap,
      clearCoordinates,
      on('audiologist-search', handleFormSubmission),
    ),
  }
}
