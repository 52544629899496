import { forTarget } from '@core/content'
import { mount } from '@core/mount'
import { Splide } from '@splidejs/splide'
import { Video } from '@splidejs/splide-extension-video'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  let main: Splide
  let thumbnails: Splide
  const videosArray: HTMLElement[] = []
  const videoThumbsArray: HTMLElement[] = []

  /**
   * handleAnyVideos
   *
   * if there are any dk-target="video-embed" elements, use them to build new slides
   */
  const handleAnyVideos = () => {
    const videos = Array.from(document.querySelectorAll<HTMLElement>('[dk-target="video-embed"]'))
    videos.forEach((video) => {
      const videoID = video.getAttribute('data-splide-youtube') || video.getAttribute('data-splide-vimeo')
      if (!videoID) return console.warn('Missing data-splide-youtube OR data-splide-vimeo')

      const videoThumb = document.createElement('div')
      const videoThumbImg = document.createElement('img')
      const videoThumbSrc = video.firstElementChild!.getAttribute('src')!

      videoThumbImg.setAttribute('src', videoThumbSrc)
      videoThumbImg.classList.add('product-images__image')
      videoThumb.classList.add('product-images__item')
      videoThumb.appendChild(videoThumbImg)

      videoThumbsArray.push(videoThumb)
      videosArray.push(video)
    })
  }

  /**
   * buildSyncedSplides
   *
   * Build and mount a thumbnail splide and a main splide, which are synced with
   * each other. If there are video slides, destroy and re-mount the main splide.
   */
  const buildSyncedSplides = () => {
    main = new Splide('[dk-target="main-carousel"]', {
      type: 'fade',
      rewind: true,
      pagination: false,
      arrows: false,
      video: {
        autoplay: true,
        disableOverlayUI: true,
        hideControls: true,
        loop: true,
        mute: true,
      },
    })

    thumbnails = new Splide('[dk-target="thumbnail-carousel"]', {
      direction: 'ttb',
      width: '17%',
      heightRatio: 6,
      perPage: 6,
      rewind: true,
      pagination: false,
      isNavigation: true,
      breakpoints: {
        767: {
          direction: 'ltr',
          heightRatio: 0.25,
          perPage: 4,
          width: 'auto',
        },
      },
    })

    main.sync(thumbnails)
    if (videosArray.length) {
      main.mount({ Video })
      main.add(videosArray).destroy().sync(thumbnails).mount()
    } else {
      main.mount()
    }

    thumbnails.mount()
    if (videosArray.length) {
      thumbnails.add(videoThumbsArray)
    }
  }

  /**
   * refreshSplidesOnClick
   *
   * Webflow loads product variant images when they're selected, which breaks the Splides.
   * After waiting 100ms for Webflow to do its thing, we refresh the Splides.
   */
  const refreshSplidesOnClick = () => {
    const getStarted1 = forTarget<HTMLLinkElement>(root, 'add-to-cart-home')
    const getStarted2 = forTarget<HTMLLinkElement>(root, 'add-to-cart-clinic')

    if (getStarted1) {
      getStarted1.addEventListener('click', () => {
        setTimeout(() => {
          main.refresh()
          thumbnails.refresh()
        }, 100)
      })
    }

    if (getStarted2) {
      getStarted2.addEventListener('click', () => {
        setTimeout(() => {
          main.refresh()
          thumbnails.refresh()
        }, 100)
      })
    }
  }

  return {
    start: mount(
      root,
      handleAnyVideos,
      buildSyncedSplides,
      refreshSplidesOnClick,
    ),
  }
}
