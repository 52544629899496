type SearchBox = google.maps.places.SearchBox

export function initGoogleSearch (
  input: HTMLInputElement,
  callback: (service: SearchBox, input: HTMLInputElement) => void,
): void {
  const searchBox = new google.maps.places.SearchBox(input)
  const bounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(50, -125),
    new google.maps.LatLng(22, -85),
  )

  searchBox.setBounds(bounds)

  google.maps.event.addListener(searchBox, 'places_changed', () => {
    const value = searchBox.getPlaces()?.[0]
    if (!value) return

    input.value = value.formatted_address || input.value
    callback(searchBox, input)
  })
}
