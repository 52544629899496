import { ComponentActions } from '@core/bootstrap'
import { mount } from '@core/mount'
import { BehaviorSubject } from 'rxjs'
import { clearContents, fromTemplate } from '@core/template'
import { forTarget } from '@core/content'
import GlobalRatingStars from '@modules/ratingStars'

type ComparisonTableProps = {
  products$: BehaviorSubject<Record<string, Product>>
}

export default function ComparisonTable (root: HTMLElement, props: ComparisonTableProps): ComponentActions {
  const { products$ } = props

  function redirectIfEmpty () {
    const products = products$.getValue()
    if (Object.keys(products).length === 0) {
      window.location.href = '/shop'
    }
  }

  function displayImageCards (products: Record<string, Product> = {}) {
    const container = forTarget<HTMLDivElement>(root, 'image-block-container')!
    clearContents(container)

    // Add audio play/pause toggle
    function displayToggles (product: Product, elem: HTMLElement) {
      const playPauseToggle = document.createElement('button')
      const playPauseToggleIcon = document.createElement('div')
      playPauseToggle.classList.add('comparison__button')
      playPauseToggleIcon.classList.add('comparison__button--play-pause')
      playPauseToggle.setAttribute('dk-slug', product.slug)
      playPauseToggle.setAttribute('dk-name', product.name)
      playPauseToggle.setAttribute('dk-value', 'product.backgroundNoise')
      playPauseToggle.setAttribute('dk-action', 'productButton:click')
      playPauseToggle.setAttribute('aria-label', 'play')
      elem.parentNode?.insertBefore(playPauseToggle, elem)
      playPauseToggle.appendChild(elem)
      playPauseToggle.appendChild(playPauseToggleIcon)
    }

    for (const product of Object.values(products)) {

      product.price = (Number(product.price) / 100).toLocaleString()
      const elem = fromTemplate('image-card', product)!

      const priceNormal = elem.querySelector<HTMLDivElement>('[dk-target="price"]')
      const priceHidden = elem.querySelector<HTMLDivElement>('[dk-target="no-price"]')
      if (product.name.toLowerCase().includes('phonak')) {
        priceNormal?.remove()
        priceHidden?.classList.remove('hide')
      }

      const image = elem.querySelector<HTMLImageElement>('img')!
      image.setAttribute('src', product.mainImage!)

      elem.querySelector<HTMLLinkElement>('a')!.setAttribute('href', `/product/${product.slug}`)

      // Add rating number
      elem.querySelector<HTMLDivElement>('.rating-number')!.innerText = `${product.ratingAverage}`

      // Add rating stars
      elem.querySelector<HTMLDivElement>('[dk-module="rating-stars"]')!.setAttribute('dk-value', `${product.ratingAverage}`)

      // Temporary check, so that these don't get added to live site :)
      const dropdown = forTarget<HTMLDivElement>(root, 'audio-comparison-dropdown')
      if (dropdown) displayToggles(product, image)

      container.appendChild(elem)
    }

    GlobalRatingStars()
  }

  function displaySpec (name: string, key: keyof Product, yesOrNo = true) {
    return function (products: Record<string, Product> = {}) {
      const container = forTarget<HTMLDivElement>(root, `${name}-container`)!
      clearContents(container)

      for (const product of Object.values(products)) {
        const value = yesOrNo ? product[key] ? 'Yes' : 'No' : product[key]
        const elem = fromTemplate(`${name}-spec`, { value })!
        container.appendChild(elem)
      }
    }
  }

  function displayScorecardSpec (name: string, key: keyof Scorecard, richTextKey: keyof Scorecard) {
    return function (products: Record<string, Product> = {}) {
      const container = forTarget<HTMLDivElement>(root, `${name}-container`)!
      clearContents(container)

      for (const product of Object.values(products)) {

        const value = product.scorecard?.[key]
        const elem = fromTemplate(`${name}-spec`, { value })!
        container.appendChild(elem)
        // const richText = product.scorecard?.[`${key}RichText`]
        const richText = product.scorecard?.[richTextKey]
        if (richText) {
          const tooltipElem = fromTemplate(`${name}-tooltip`, product)!
          tooltipElem.querySelector<HTMLDivElement>('.product-comparison__tooltip-copy')!.innerHTML = richText
          elem.appendChild(tooltipElem)
        }
      }
    }
  }

  return {
    watch: [{
      target: products$,
      actions: [
        redirectIfEmpty,
        displayImageCards,

        displaySpec('bluetooth', 'bluetooth'),
        displaySpec('rechargeable', 'rechargeable'),
        displaySpec('in-ear', 'inEar'),
        displaySpec('behind-ear', 'behindEar'),
        displaySpec('type-of-hearing-loss', 'typeOfHearingLoss', false),

        displayScorecardSpec('hearing-loss-level', 'hearingLossLevel', 'hearingLossLevelRichText'),
        displayScorecardSpec('care-model', 'careModel', 'careModelRichText'),
        displayScorecardSpec('sound-quality', 'soundQuality', 'soundQualityRichText'),
        displayScorecardSpec('comfort', 'comfort', 'comfortRichText'),
        displayScorecardSpec('style', 'style', 'styleRichText'),
        displayScorecardSpec('battery', 'battery', 'batteryRichText'),
        displayScorecardSpec('connection', 'connection', 'connectionRichText'),
        displayScorecardSpec('dexterity-level', 'dexterityLevel', 'dexterityLevelRichText'),
        displayScorecardSpec('waterproof-level', 'waterproofLevel', 'waterproofLevelRichText'),
        displayScorecardSpec('app-features', 'appFeatures', 'appFeaturesRichText'),
      ],
    }],
    start: mount(root, () => {}),
  }
}
