/**
 * BlogFiltersQueryParams
 *
 * Uses query params to click appropriate filter on Blog Filter page. If no matching
 * filter is specified here, no filter is clicked
 */
const BlogFiltersQueryParams = () => {
  const params = new URLSearchParams(window.location.search)
  const filter = params.get('filter')
  let filterToClick

  filterToClick = document.querySelector<HTMLInputElement>(`[dk-filter-item-value=${filter}]`)
  if (filterToClick) filterToClick.click()
}

export default BlogFiltersQueryParams
