import { assertNonNullish } from '@lib/assert'
import { BehaviorSubject } from 'rxjs'

export enum ScreenActions {
  Previous,
  Next,
  Restart,
  SkipTo,
}
export enum Steps {
  Step0,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  Complete,
}

export const screensStateMachine: Record<Steps, Record<any, Steps>> = {
  [Steps.Step0]: {
    [ScreenActions.Next]: Steps.Step1,
  },
  [Steps.Step1]: {
    // Questionnaire
    [ScreenActions.Previous]: Steps.Step0,
    [ScreenActions.Next]: Steps.Step2,
    [ScreenActions.SkipTo]: Steps.Step3,
  },
  [Steps.Step2]: {
    // If any "yes" answers, see an ENT
    [ScreenActions.Previous]: Steps.Step1,
    [ScreenActions.Next]: Steps.Step3,
  },
  [Steps.Step3]: {
    // Quiet Room
    [ScreenActions.Previous]: Steps.Step1,
    [ScreenActions.Next]: Steps.Step4,
  },
  [Steps.Step4]: {
    // Headphones
    [ScreenActions.Previous]: Steps.Step3,
    [ScreenActions.Next]: Steps.Step5,
  },
  [Steps.Step5]: {
    // Calibration Part 1
    [ScreenActions.Previous]: Steps.Step4,
    [ScreenActions.Next]: Steps.Step6,
  },
  [Steps.Step6]: {
    // Calibration Part 2
    [ScreenActions.Previous]: Steps.Step5,
    [ScreenActions.Next]: Steps.Step7,
  },
  [Steps.Step7]: {
    // Left Ear
    [ScreenActions.Previous]: Steps.Step6,
    [ScreenActions.Next]: Steps.Step8,
    [ScreenActions.Restart]: Steps.Step6,
  },
  [Steps.Step8]: {
    // Right Ear
    [ScreenActions.Previous]: Steps.Step7,
    [ScreenActions.Next]: Steps.Step9,
    [ScreenActions.Restart]: Steps.Step7,
  },
  [Steps.Step9]: {
    // Results
    [ScreenActions.Previous]: Steps.Step7,
    [ScreenActions.Next]: Steps.Complete,
    [ScreenActions.Restart]: Steps.Step7,
  },
  [Steps.Complete]: {
    // Next Steps
    [ScreenActions.Previous]: Steps.Step9,
    [ScreenActions.Restart]: Steps.Step7,
  },
} as const

export const performAction = (
  action: ScreenActions,
  skip$: BehaviorSubject<boolean>,
  audioPlayer: HTMLAudioElement,
  currentScreenState$: BehaviorSubject<Steps>,
) => {
  return (_event: unused, _props: unused, element: HTMLButtonElement) => {
    if (element.disabled) return
    const currentStep = currentScreenState$.getValue()
    const nextState = screensStateMachine[currentStep][action]

    try {
      assertNonNullish(
        nextState,
        `${ScreenActions[action]} is not defined in your state machine for Step${currentStep}`,
      )
      currentScreenState$.next(screensStateMachine[currentStep][action])
      skip$.next(false) // reset default step behavior
      audioPlayer.pause() // if any audio is still playing, stop it
    } catch (error) {
      console.error(error)
    }
  }
}
