import { forTarget } from '@core/content'
import { mount } from '@core/mount'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  /**
   * reorderRelatedProducts
   *
   * Finds current product w/dk-target="related-item-current" and inserts it into
   * the correct spot in the Related Products list, if it exists
   */
  const reorderRelatedProducts = () => {
    const currentProduct = forTarget<HTMLElement>(root, 'related-item-current')!
    if (!currentProduct.getAttribute('dk-value')) return

    // finds desired product position, returns early if this number isn't set in the CMS
    const currentProductPosition = currentProduct.getAttribute('dk-value')
    if (currentProductPosition === null) return

    // finds related products list, returns early if it doesn't exist for this product
    const relatedProductsList = forTarget<HTMLElement>(root, 'related-items-list')
    if (!relatedProductsList) return

    // finds the related product that will follow the desired product position
    const subsequentProduct = relatedProductsList.childNodes.item(parseInt(currentProductPosition) - 1)

    // inserts the current product into the desired position
    relatedProductsList.insertBefore(currentProduct, subsequentProduct)
  }

  return {
    start: mount(root, reorderRelatedProducts),
  }
}
