/**
 * Creates an element from a template
 *
 * @param templateName
 * @param obj
 * @returns
 */
export function fromTemplate (templateName: string, obj: Record<string, any>): Maybe<HTMLElement> {
  const element = document.querySelector<HTMLElement>(`[dk-template="${templateName}"]`)!
  const newElement = element.cloneNode(true)! as HTMLElement
  newElement.removeAttribute('dk-template')

  Object.keys(obj).forEach((key) => {
    const target = newElement.querySelector(`[dk-value="${key}"]`) as Maybe<HTMLElement>
    if (target) target.innerText = obj[key]
  })

  return newElement
}

export function clearContents (element: HTMLElement) {
  element.innerHTML = ''
}
