import FiltersQueryParams from '@modules/FiltersQueryParams'
import FiltersShop from '@modules/FiltersShop'
import type { ComponentActions } from '@core/bootstrap'
import { BehaviorSubject } from 'rxjs'
import { ProductsObservable, setProductComparisonData, toggleProductSelection } from '@lib/comparisonTrigger'
import { assertNonNullish } from '@lib/assert'
import { clearContents, fromTemplate } from '@core/template'
import { forTarget } from '@core/content'
import { mount } from '@core/mount'
import { on } from '@core/on'
import { setRatingStars } from '@modules/ratingStars'

type FilterComponentProps = {
  filteredProducts$: BehaviorSubject<Record<string, any>[]>
  products$: ProductsObservable
}

export default function (root: RootElement, { filteredProducts$, products$ }: FilterComponentProps): ComponentActions {

  /**
   * showNoResultMessage
   *
   * @param container
   */
  function showNoResultsMessage (container: HTMLDivElement) {
    container.innerHTML = '<div>No products match this criteria.</div>'
  }

  /**
   * updateLinks
   *
   * @param item
   * @param templateEl
   */
  function updateLinks (item: any, templateEl: HTMLElement) {
    forTarget<HTMLLinkElement>(templateEl, 'link-url')!.href = `/product/${item.slug}`
  }

  /**
   * updateImages
   *
   * @param item
   * @param templateEl
   */
  function updateImages (item: any, templateEl: HTMLElement) {
    const image = forTarget<HTMLImageElement>(templateEl, 'image')!
    image.alt = item.name
    image.src = item['default-sku']['main-image'].url
  }

  /**
   * updateRating
   *
   * @param item
   * @param templateEl
   */
  function updateRating (item: any, templateEl: HTMLElement) {
    const starsGroup = forTarget<HTMLDivElement>(templateEl, 'rating-stars')!
    const starsNumber = document.createElement('div')

    starsGroup.setAttribute('dk-value', item['average-rating'])
    starsGroup.insertAdjacentElement('beforebegin', starsNumber)

    starsNumber.classList.add('rating-number')
    starsNumber.innerText = item['average-rating']

    setRatingStars(starsGroup)
  }

  /**
   * updateProductVariantStripe
   *
   * @param item
   * @param templateEl
   */
  function updateProductVariantStripe (item: any, templateEl: HTMLElement) {
    const bestSellerLabel = forTarget<HTMLDivElement>(templateEl, 'if-best-seller')!
    if (item['product-variant-stripe-text']) {
      bestSellerLabel.classList.remove('hidden')
    }
  }

  /**
   * normalizeItem
   *
   * @param item
   */
  function normalizeItem (item: any) {
    item.price = `$${new Intl.NumberFormat('en-US').format((item['default-sku'].price.value) / 100)}`
  }

  /**
   * updatePrice
   *
   * @param item
   * @param el
   */
  function updatePrice (item: any, el: HTMLElement) {
    const priceElement = el.querySelector<HTMLElement>('[dk-hidden-value="default-sku.price.value"]')
    const customPriceElement = el.querySelector<HTMLElement>('[dk-target="custom-price"]')

    // show custom price element if item name includes "phonak"
    if (item['name'].toLowerCase().includes('phonak')) {
      // remove the entire normal price element from the template
      priceElement?.remove()
      customPriceElement?.classList.remove('hide')
      return
    } else {
      // remove the custom price element from the template
      customPriceElement?.remove()
    }
  }

  /**
   * addDataAttributesToInfoButton
   *
   * @param item
   * @param el
   */
  function addDataAttributesToInfoButton (item: Record<string, any>, el: Element) {
    const span = forTarget<HTMLSpanElement>(el, 'product-information')!

    span.setAttribute('data-slug', item.slug)
    span.setAttribute('data-name', item.name)
  }

  /**
   * populateContainer
   *
   * @param items
   */
  function populateContainer (items: Maybe<Record<string, any>[]>) {
    if (!items) return
    const container = root.querySelector<HTMLDivElement>('[dk-filter-target]')!
    assertNonNullish(container, 'Missing element with [dk-filter-target]')
    clearContents(container)

    if (!items.length) return showNoResultsMessage(container)
    for (const item of items) {
      normalizeItem(item)
      const el = fromTemplate('product-item', item)!
      updatePrice(item, el)
      updateLinks(item, el)
      updateImages(item, el)
      updateRating(item, el)
      updateProductVariantStripe(item, el)
      addDataAttributesToInfoButton(item, el)
      assertNonNullish(el, 'No items template')
      on('compare-product', (event: CustomEvent) => setProductComparisonData(event, products$))(el)
      toggleProductSelection(el, products$)

      container.append(el)
    }
  }

  return {
    start: mount(
      root,
      () => FiltersShop(filteredProducts$),
      FiltersQueryParams,
    ),
    watch: [
      {
        target: filteredProducts$,
        actions: [populateContainer],
      },
    ],
  }
}
