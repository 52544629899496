/**
 * applyStyles
 *
 * Applies a set of styles to a given HMTLElement
 *
 * @param element
 * @param styles
 */
export function applyStyles (element: HTMLElement, styles: Partial<CSSStyleDeclaration>) {
  Object.assign(element.style, styles)
}
