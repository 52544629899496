import { mount } from '@core/mount'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  /**
   * playInterstitial
   *
   * grabs the link's original href and appends it as a query string,
   * plus a replacement message, if any,
   * then opens the interstitial page in a new tab
   *
   * @param event
   */
  function playInterstitial (event: Event) {
    const target = event.target as HTMLAnchorElement
    const targetLink = target.tagName === 'A' ? target.href : target.closest('a')!.href
    const href = targetLink
    if (!href) return console.warn('href is required')
    const message = target.getAttribute('dk-message')

    event.preventDefault()

    if (message) {
      window.open(`/leaving-soundly?href=${href}&message=${message}`, '_blank')
    } else {
      window.open(`/leaving-soundly?href=${href}`, '_blank')
    }
  }

  /**
   * ExternalLinks
   *
   * adds click listener triggering interstitial if root is a valid link
   */
  function ExternalLinks () {
    const thisDomain = location.hostname

    if ((root as HTMLElement).tagName !== 'A') {
      return console.warn('interstitial was designed for link elements')
    }

    // if href points to external url AND is not a placeholder
    if (
      (root as HTMLAnchorElement).href &&
      !(root as HTMLAnchorElement).hostname.includes(thisDomain) &&
      (root as HTMLAnchorElement).href !== '#' &&
      (root as HTMLAnchorElement).href !== '?'
    ) {
      // add click listener to play interstitial
      (root as HTMLAnchorElement).addEventListener('click', playInterstitial)
    }
  }

  return {
    start: mount(root, ExternalLinks),
  }
}
