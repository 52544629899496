import { mount } from '@core/mount'
import { Splide } from '@splidejs/splide'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  const buildSplides = () => {
    const splides = root.querySelectorAll('[dk-target="splide"]')!

    Array.from(splides).forEach((splide) => {
      const list = splide.querySelector('.splide__list')
      if (list && list.childNodes.length >= 1) {
        new Splide(splide as HTMLElement, {
          perPage: 1,
          mediaQuery: 'min',
          pagination: false,
          breakpoints: {
            768: {
              perPage: 3,
              padding: {
                right: '10%',
              },
            },
          },
        }).mount()
      }
    })
  }

  return {
    start: mount(root, buildSplides),
  }
}
