import { convertTag } from './helpers'

const ESCAPE_KEY = 'Escape'

export default function NavDropdowns() {
  // find all dropdown toggles
  // use them as the logical basis for managing dropdowns
  Array.from(document.querySelectorAll('[dk-nav-dropdown-toggle]')).forEach((toggle) => {
    // basic state management
    let opened = false
    let mobileOnly = false

    // handle scenarios in which dropdowns
    // should only function on mobile:
    if (!(toggle.getAttribute('dk-mobile-only') === null)) {
      mobileOnly = true
      let mobileBreakpoint = toggle.getAttribute('dk-mobile-only')
      if (mobileBreakpoint === '#') mobileBreakpoint = '991'
      const mobileBreakpointForMediaQuery = parseInt(mobileBreakpoint!) + 1
      const mediaQuery = window.matchMedia(`(min-width: ${mobileBreakpointForMediaQuery}px)`)

      // check if window is currently desktop-size
      function isDesktop() {
        return mediaQuery.matches
      }
      if (isDesktop()) {
        toggle.removeAttribute('aria-expanded')
      } else {
        toggle.setAttribute('aria-expanded', 'false')
      }

      // when window resized, re-check if desktop or mobile
      let timeoutFunctionId: NodeJS.Timeout
      window.addEventListener('resize', () => {
        clearTimeout(timeoutFunctionId)
        timeoutFunctionId = setTimeout(() => {
          if (isDesktop() && opened) {
            closeDropdown()
            toggle.removeAttribute('aria-expanded')
          }
          if (isDesktop()) toggle.removeAttribute('aria-expanded')
          if (!isDesktop && opened) {
            toggle.setAttribute('aria-expanded', 'true')
          }
        }, 350)
      })
    }

    // for all other dropdowns:
    let dropdownID = toggle.getAttribute('dk-nav-dropdown-toggle')
    // if a target id is specified,
    if (!(dropdownID === '#') && !(dropdownID === null)) {
      // try to find a target element with that id
      let dropdown = document.getElementById(dropdownID)
      if (!dropdown) return
      // if found, add appropriate roles
      dropdown.setAttribute('role', 'menu')
      let menuLinks = dropdown.getElementsByTagName('a')
      Array.from(menuLinks).forEach((link) => {
        link.setAttribute('role', 'menuitem')
      })
    }

    // format the dropdown toggle itself
    toggle = convertTag(toggle as HTMLElement, 'button')
    toggle.setAttribute('type', 'button')

    // if allowed to function on desktop, label as unexpanded on page load
    if (!mobileOnly) toggle.setAttribute('aria-expanded', 'false')

    const dropdownParent = toggle.closest('[dk-nav-dropdown-parent]') || toggle.parentElement!

    function openDropdown() {
      // label as expanded
      toggle.setAttribute('aria-expanded', 'true')
      // add .open class to parent to visually "open" dropdown
      // note: this assumes your CSS will handle hide/show properly
      dropdownParent.classList.add('open')
      // update opened "state"
      opened = true
      // scroll lock is desired when in a nav, not desired if elsewhere on page
      if (toggle.closest('nav') !== null) {
        document.body.style.overflow = 'hidden'
      }
      // listen for click events outside dropdown
      document.addEventListener('click', closeDropdownOnOutsideClick)
      // listen for escape key press
      document.addEventListener('keyup', closeDropdownOnEscPress)
      // listen for focus moving outside of dropdown
      window.addEventListener('focusin', closeDropdownOnOutsideFocus)
    }

    function closeDropdown() {
      // label as collapsed
      toggle.setAttribute('aria-expanded', 'false')
      // remove .open class from parent to visually "close" dropdown
      dropdownParent.classList.remove('open')
      // update opened "state"
      opened = false
      // remove scroll lock
      if (toggle.closest('nav') !== null) {
        document.body.style.overflow = 'auto'
      }
      // remove listeners since no longer needed
      document.removeEventListener('click', closeDropdownOnOutsideClick)
      document.removeEventListener('keyup', closeDropdownOnEscPress)
      window.removeEventListener('focusin', closeDropdownOnOutsideFocus)
    }

    const closeDropdownOnOutsideClick = (event: Event) => {
      if (!dropdownParent!.contains(event.target as Node)) {
        closeDropdown()
      }
    }

    const closeDropdownOnOutsideFocus = (event: FocusEvent) => {
      if (!dropdownParent!.contains(event.target as Node)) {
        closeDropdown()
      }
    }

    function closeDropdownOnEscPress(event: KeyboardEvent) {
      if (event.key === ESCAPE_KEY) {
        event.preventDefault()
        closeDropdown()
        ;(toggle as HTMLElement).focus()
      }
    }

    function handleToggleClick(event: Event) {
      event.preventDefault()
      if (!opened) {
        openDropdown()
      } else {
        closeDropdown()
      }
    }

    // attach click listener to toggle
    toggle.addEventListener('click', (event) => {
      handleToggleClick(event)
    })

    // find any close buttons
    Array.from(document.querySelectorAll('[dk-nav-dropdown-close]')).forEach((closer) => {
      const dropdownParent = closer.closest('[dk-nav-dropdown-parent]')
      if (!dropdownParent) return
      closer.addEventListener('click', function () {
        if (dropdownParent.classList.contains('open')) {
          closeDropdown()
        }
      })
    })
  })

  // hoist .w--current up to parent dropdown, if present
  Array.from(document.querySelectorAll('.w--current:not(.never-current)')).forEach((currentItem) => {
    const dropdownParent = currentItem.closest('[dk-nav-dropdown-parent]')
    if (!dropdownParent) return
    // add .w--current to the element just inside the dropdown parent, for styling
    dropdownParent.firstElementChild!.classList.add('w--current')
  })
}
