/**
 * setRatingStars
 *
 * Converts an out-of-5 rating value into a percentage, then uses
 * that percentage to set the inline width of the colored-in stars
 */
export const setRatingStars = (starsGroup: HTMLElement) => {
  const ratingNumber = parseFloat(starsGroup.getAttribute('dk-value')!)
  const ratingPercentage = (ratingNumber * 100) / 5
  const frontRow = starsGroup.querySelector<HTMLElement>('[dk-stars-front]')

  starsGroup.setAttribute('aria-label', `${ratingNumber.toString()} out of 5 star rating`)
  starsGroup.setAttribute('title', `${ratingNumber.toString()} out of 5 stars`)
  starsGroup.setAttribute('role', 'img')

  if (!frontRow) return console.warn('Missing element with [dk-stars-front]')
  frontRow.style.width = `${ratingPercentage.toString()}%`
}

const GlobalRatingStars = () => {
  Array.from(document.querySelectorAll<HTMLElement>('[dk-module="rating-stars"][dk-value]')).forEach((starsGroup) => {
     setRatingStars(starsGroup)
  })
}

export default GlobalRatingStars
