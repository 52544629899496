import { mount } from '@core/mount'
import { Splide } from '@splidejs/splide'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  let main: Splide
  let thumbnails: Splide

  /**
   * buildSyncedSplides
   *
   * Build and mount a thumbnail splide and a main splide,
   * which are synced with each other.
   */
  const buildSyncedSplides = () => {
    main = new Splide('[dk-target="main-carousel-press"]', {
      type: 'fade',
      rewind: true,
      pagination: false,
      arrows: false,
    })

    thumbnails = new Splide('[dk-target="thumbnail-carousel-press"]', {
      perPage: 6,
      rewind: true,
      pagination: false,
      arrows: false,
      isNavigation: true,
    })

    main.sync(thumbnails)
    main.mount()
    thumbnails.mount()
  }

  return {
    start: mount(
      root,
      buildSyncedSplides,
    ),
  }
}
