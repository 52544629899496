import { mount } from '@core/mount'
import { Splide } from '@splidejs/splide'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {
  const buildSplide = () => {
    const list = root.querySelector('.splide__list')
    if (list && list.childNodes.length > 1) {
      new Splide(root as HTMLElement, {
        arrows: false,
        pagination: false,
        autoplay: true,
        interval: 2000,
        direction: 'ttb',
        type: 'loop',
        height: 50,
        speed: 1000,
      }).mount()
    }
  }

  return {
    start: mount(root, buildSplide),
  }
}
