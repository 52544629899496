/**
 * assertNonNullish
 *
 * assert that a value is not null or undefined
 *
 * @param value
 * @param message
 */
export function assertNonNullish<T> (value: unknown, message: string): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw Error(message)
  }
}

/**
 * assertNonEmpty
 *
 * asserts that an array is not empty, undefined, or null
 *
 * @param value
 * @param message
 */
export function assertNonEmpty<T> (value: unknown[], message: string): asserts value is T[] {
  if (!value?.length) {
    throw Error(message)
  }
}

/**
 * assertTruthy
 *
 * asserts that the value is truthy
 *
 * @param value
 * @param message
 */
export function assertTruthy (value: unknown, message: string) {
  if (!value) {
    throw Error(message)
  }
}
