import Filters from '@modules/Filters'
import type { BehaviorSubject } from 'rxjs'

export default (filteredProducts$: BehaviorSubject<Record<string, any>[]>) => {
  // eslint-disable-next-line no-new
  const filters = new Filters()

  filters.handleFiltering = async () => {
    const { data } = await filters.filterRequest()
    filters.updateDOM(data)
  }

  filters.updateDOM = (items: []) => {
    filteredProducts$.next(items)
  }
}
